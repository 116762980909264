<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    width: 77.2%;
    max-width: 1112px;
    margin: 0 auto;
    padding: 130px 0;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      position: relative;
      display: flex;
      justify-content: flex-start;
      &-email {
        position: absolute;
        right: 20px;
        line-height: 60px;
        color: #e6b800;
        z-index: 2;
      }
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      height: 136px;
      margin-top: 10px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 10px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
        // &-main {
        //   height: 40px;
        //   margin-left: 5%;
        //   border-left: 2px solid #ccc;
        //   padding-left: 1%;
        //   font-size: 20px;
        //   line-height: 40px;
        // }
      }
      &-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        line-height: 40px;
        margin-top: 20px;
        margin-left: 5%;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        p {
          margin: 0;
        }
        &-left {
          width: 15%;
          p::before {
            content: " · ";
          }
        }
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  .actived {
    font-size: 14px;
    font-weight: 600;
    color: rgba(85, 119, 224, 1);
    background: rgba(30, 33, 43, 1);
    border-bottom: 2px solid rgba(85, 119, 224, 1);
  }
  .missing-information {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(7, 9, 17, 0.8);
    z-index: 12;
    &-container {
      width: 460px;
      height: 240px;
      background: rgba(23, 25, 32, 1);
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-close {
        width: 18px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        margin: 0;
        text-align: center;
        &:nth-child(2) {
          margin: 30px 0;
        }
        &:nth-child(3) {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          padding: 0 23px;
        }
      }
    }
    &-paaaword {
      width: 400px;
      height: 367px;
      background: rgba(15, 17, 24, 1);
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-family: "PingFangSC-Regular", "PingFang SC";
      font-weight: 400;
      color: rgba(85, 119, 224, 1);
      line-height: 25px;
    }
  }
  .sure-button {
    width: 140px;
    height: 40px;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
    background: #070911;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-nav">
        <div class="person-center-wrapper-nav-item"
             v-for="(item, index) in personCenterNav"
             :key="index"
             @click="changeModal(index)"
             :class="index === activedIndex ? 'actived' : ''">
          <template v-if="item.name !== 'moreJ' && item.name !== 'moreC'">
            <img :src="item.img"
                 alt="">
            {{ $t(item.name) }}
          </template>
          <template v-else-if="item.name === 'moreJ' && showJiangNan">
            <span>
              <img :src="item.img"
                   alt="">
              {{ $t(item.name) }}
            </span>
          </template>
          <template v-else-if="item.name === 'moreC' && showJiangNan">
            <span>
              <img :src="item.img"
                   alt="">
              {{ $t(item.name) }}
            </span>
          </template>
        </div>
        <div class="person-center-wrapper-nav-email"
             v-if="$Cookies.get('cecc-lang') === 'en'">Contact email：cecc2016@126.com</div>

      </div>
      <Notice v-if="activedIndex === 0"></Notice>
      <template v-else-if="activedIndex === 1">
        <div class="person-center-wrapper-selfInfo">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{ $t('registrationInfo') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <div class="person-center-wrapper-selfInfo-item-left">
              <p>
                {{ $t('accountName') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-right"
                 :style="{display: 'flex',width: '65%',justifyContent: 'flex-start'}">
              <p :style="{width: '20%'}">
                {{ userInfo.email }}
              </p>
              <div class="person-center-wrapper-button"
                   @click="logout">
                {{ $t('logout') }}
              </div>
              <div class="person-center-wrapper-button"
                   @click="changePassword">
                {{ $t('changePassword') }}
              </div>
              <div class="person-center-wrapper-button"
                   @click="help">
                {{ $t('help') }}
              </div>
            </div>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo"
             :style="{height: '355px'}">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{ $t('submitterInfo') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <div class="person-center-wrapper-selfInfo-item-left">
              <p>
                {{$t('name')}}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-right">
              <p>
                <el-input v-model="userInfo.first_name"
                          :placeholder="$t('firstName')"
                          :style="{width: '255px'}"></el-input>
                <el-input v-model="userInfo.last_name"
                          :placeholder="$t('lastName')"
                          :style="{width: '255px', marginLeft: '20px'}"></el-input>
              </p>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <div class="person-center-wrapper-selfInfo-item-left">
              <p>
                {{$t('identity')}}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-right">
              <p>
                <el-select v-model="userInfo.org_type"
                           filterable
                           :placeholder="$t('identitySelect')"
                           :style="{width: '530px'}">
                  <el-option v-for="item in orgTypeList"
                             :key="item.value"
                             :label="$t(item.label)"
                             :value="item.value">
                  </el-option>
                </el-select>
              </p>
            </div>
          </div>
          <template v-if="userInfo.org_type == 1">
            <div class="person-center-wrapper-selfInfo-item">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p>
                  {{ $t('unitName') }}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-input v-model="userInfo.org_name"
                            :placeholder="$t('enterUnitName')"
                            :style="{width: '530px'}"></el-input>
                </p>
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p :style="{lineHeight: '20px'}">
                  {{$t('employeeNumber')}}/{{$t('occupation')}}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-select v-model="userInfo.title1"
                             filterable
                             :placeholder="$t('chooseNumberOfEmployees')"
                             :style="{width: '255px'}">
                    <el-option v-for="item in sizeList"
                               :key="item.value"
                               :label="$t(item.label)"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <el-input v-model="userInfo.title2"
                            :placeholder="$t('fillInYourOc')"
                            :style="{width: '255px', marginLeft: '20px'}"></el-input>
                </p>
              </div>
            </div>
          </template>
          <template v-else-if="userInfo.org_type == 3">
            <div class="person-center-wrapper-selfInfo-item">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p :style="{lineHeight: '20px'}">
                  {{$t('P1')}}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-input v-model="userInfo.org_name"
                            :placeholder="$t('P2')"
                            :style="{width: '530px'}"></el-input>
                </p>
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p>
                  {{$t('occupation')}}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-input v-model="userInfo.title2"
                            :placeholder="$t('fillInYourOc')"
                            :style="{width: '255px'}"></el-input>
                </p>
              </div>
            </div>
          </template>
          <template v-else-if="userInfo.org_type == 4">
            <div class="person-center-wrapper-selfInfo-item">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p>
                  {{$t('P3')}}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-input v-model="userInfo.org_name"
                            :placeholder="$t('P4')"
                            :style="{width: '530px'}"></el-input>
                </p>
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p :style="{lineHeight: '20px'}">
                  {{$t('P5')}}/{{$t('occupation')}}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-select v-model="userInfo.title1"
                             filterable
                             :placeholder="$t('P6')"
                             :style="{width: '255px'}">
                    <el-option v-for="item in designDirectonList"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <el-input v-model="userInfo.title2"
                            :placeholder="$t('fillInYourOc')"
                            :style="{width: '255px', marginLeft: '20px'}"></el-input>
                </p>
              </div>
            </div>
          </template>
          <template v-else-if="userInfo.org_type == 5">
            <div class="person-center-wrapper-selfInfo-item">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p>
                  {{$t('Other')}}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-input v-model="userInfo.org_name"
                            :placeholder="$t('enterOther')"
                            :style="{width: '530px'}"></el-input>
                </p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="person-center-wrapper-selfInfo-item">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p>
                  {{$t('InstitutionName')}}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-input v-model="userInfo.org_name"
                            :placeholder="$t('enterInstitutionName')"
                            :style="{width: '530px'}"></el-input>
                  <!-- <el-select v-model="userInfo.org_name"
                           filterable
                           placeholder="请选择"
                           :style="{width: '530px'}">
                  <el-option v-for="item in schoolList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select> -->
                </p>
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item"
                 :style="{marginTop: '0px'}">
              <div class="person-center-wrapper-selfInfo-item-left">
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <el-checkbox-group v-model="org_job">
                  <el-checkbox label="1">{{$t('teacher')}}</el-checkbox>
                  <el-checkbox label="2">{{$t('student')}}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-item"
                 :style="{marginTop: '0px'}">
              <div class="person-center-wrapper-selfInfo-item-left">
                <p :style="{lineHeight: '20px'}">
                  {{$t('Education')}}/{{$t('AcademicTitle')}}
                </p>
              </div>
              <div class="person-center-wrapper-selfInfo-item-right">
                <p>
                  <el-select v-model="userInfo.title1"
                             filterable
                             :placeholder="$t('chooseEductionLevel')"
                             :style="{width: '255px'}">
                    <el-option v-for="item in seList"
                               :key="item.value"
                               :label="$t(item.label)"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <el-input v-model="userInfo.title2"
                            :placeholder="$t('fillInYourAc')"
                            :style="{width: '255px', marginLeft: '20px'}"></el-input>
                  <!-- <el-select v-model="userInfo.title2"
                           :placeholder="$t('fillInYourAc')"
                           :style="{width: '255px', marginLeft: '20px'}">
                  <el-option v-for="item in schoolList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select> -->
                </p>
              </div>
            </div>
          </template>
        </div>
        <div class="person-center-wrapper-selfInfo"
             :style="{height: '341px'}">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{$t('contactInfo')}}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <div class="person-center-wrapper-selfInfo-item-left">
              <p>
                {{$t('mobilePhone')}} / {{$t('Wechat')}}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-right">
              <p>
                <el-input v-model="userInfo.mobile"
                          :placeholder="$t('enterTelPhone')"
                          :style="{width: '255px'}"></el-input>
                <el-input v-model="userInfo.wechat"
                          :placeholder="$t('enterTelWechat')"
                          :style="{width: '255px', marginLeft: '20px'}"></el-input>
              </p>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <div class="person-center-wrapper-selfInfo-item-left">
              <p>
                {{$t('Email')}}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-right">
              <p>
                <el-input v-model="userInfo.email"
                          :placeholder="$t('Email')"
                          disabled
                          :style="{width: '530px'}"></el-input>
              </p>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <div class="person-center-wrapper-selfInfo-item-left">
              <p :style="{lineHeight: '20px'}">
                {{$t('Region')}}/{{$t('fixedLineTelephone')}}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-right">
              <p>
                <el-select v-model="userInfo.country"
                           filterable
                           :placeholder="$t('selectRegion')"
                           :style="{width: '255px'}">
                  <el-option v-for="item in citys"
                             :key="item.value"
                             :label="$Cookies.get('cecc-lang') === 'en' ? item.shortName : item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
                <el-input v-model="userInfo.land_line"
                          :placeholder="$t('enterFixedLineTelephone')"
                          :style="{width: '255px', marginLeft: '20px'}"></el-input>
              </p>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item"
               :style="{marginTop: '0px'}">
            <div class="person-center-wrapper-selfInfo-item-left">
            </div>
            <div class="person-center-wrapper-selfInfo-item-right">
              <p>
                <el-checkbox-group v-model="moreTips">
                  <el-checkbox label="agree">{{$t('Ihaveread')}}</el-checkbox>
                  <el-checkbox label="submit">{{$t('NewsletterSubscription')}}</el-checkbox>
                </el-checkbox-group>
              </p>
            </div>
          </div>
          <div class="person-center-wrapper-button"
               @click="saveUserInfo">
            {{saveInfoFlag ? $t('modifyInformation') : $t('saveInformation')}}
          </div>
        </div>
      </template>
      <IDC-award v-else-if="activedIndex === 2"
                 @toSignUp="toSignUp"></IDC-award>
      <template v-else-if="activedIndex === 3">
        <production-list v-if="showProductionList"
                         @toProduction="toProduction"></production-list>
        <Person-center-production v-else
                                  :productionType="productionType"
                                  :productionIDC="productionIDC"
                                  @backList="backList"></Person-center-production>
      </template>
      <IDC-certificate v-else-if="activedIndex === 4"></IDC-certificate>
      <template v-else-if="activedIndex === 5">
        <jiangnan-list v-if="showJiangnanList"
                       @toJiangnan="toJiangnan"></jiangnan-list>
        <Jiang-nan v-else
                   :jiangnanWork="jiangnanWork"
                   @backList="backJiangnanList">

        </Jiang-nan>

      </template>
      <enterprise-demand v-else-if="activedIndex === 6 "></enterprise-demand>
    </div>
    <div class="missing-information"
         v-if="missingInformation">
      <div class="missing-information-container">
        <img src="../assets/close.png"
             alt=""
             class="missing-information-container-close"
             @click="updateInformation">
        <p>
          {{$t('tips')}}
        </p>
        <p>
          {{$t('tips1')}}
        </p>
        <div class="sure-button"
             @click="updateInformation"
             :style="{marginTop: '40px'}">
          {{$t('confirm')}}
        </div>
      </div>
    </div>
    <div class="missing-information"
         v-if="changePasswordModal">
      <div class="missing-information-paaaword">
        <img src="../assets/close.png"
             alt=""
             class="missing-information-container-close"
             @click="changePasswordModal = false">
        <p>{{$t('changePassword')}}</p>
        <el-input v-model="oldPassword"
                  :placeholder="$t('P7')"
                  show-password
                  :style="{width: '340px',marginTop: '30px'}"></el-input>
        <el-input v-model="newPassword"
                  :placeholder="$t('P8')"
                  show-password
                  :style="{width: '340px',marginTop: '30px'}"></el-input>
        <el-input v-model="newPassword1"
                  :placeholder="$t('P9')"
                  show-password
                  :style="{width: '340px',marginTop: '30px'}"></el-input>
        <div class="sure-button"
             @click="changePsd"
             :style="{marginTop: '40px'}">
          {{$t('P10')}}
        </div>
      </div>
    </div>
    <div class="missing-information"
         v-if="infoModal">
      <div class="missing-information-paaaword"
           :style="{lineHeight: '227px'}">
        <img src="../assets/close.png"
             alt=""
             class="missing-information-container-close"
             @click="infoModal = false">
        <p>{{$t('L24')}}</p>
      </div>
    </div>
    <Common-footer :style="{background: '#0F1118'}"></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';
import Notice from './notice.vue';
import IDCAward from './IDC-award.vue';
import IDCCertificate from './IDC-certificate.vue';
import PersonCenterProduction from './person-center-production.vue';
import enterpriseDemand from './enterprise-demand.vue';
import productionList from './production-list.vue';
import jiangnanList from './jiangnan-list.vue';
import JiangNan from './jiang-nan.vue';
import cityJSON from './city.json'

export default {
  name: 'person-center',
  data () {
    return {
      missingInformation: false,
      changePasswordModal: false,
      infoModal: false,
      oldPassword: '',
      newPassword: '',
      newPassword1: '',
      personCenterNav: [
        {
          name: 'notice',
          img: require('../assets/idc-award/8.png')
        },
        {
          name: 'account',
          img: require('../assets/idc-award/12.png')
        },
        {
          name: 'moreT',
          img: require('../assets/idc-award/6.png')
        },
        {
          name: 'entryList',
          img: require('../assets/idc-award/9.png')
        },
        {
          name: 'certificate',
          img: require('../assets/idc-award/11.png')
        },
        {
          name: 'moreJ',
          img: require('../assets/idc-award/13.png')
        },
        {
          name: 'moreC',
          img: require('../assets/idc-award/13.png')
        },
        // {
        //   name: 'moreM',
        //   img: require('../assets/idc-award/13.png')
        // }
      ],
      activedIndex: 1,
      citys: cityJSON.citys,
      moreTips: [],
      orgTypeList: [
        {
          "label": "Institution",
          "value": 2
        }, {
          "label": "Enterprise",
          "value": 1
        }, {
          "label": "SocialIndividuals",
          "value": 3
        }, {
          "label": "designCompany",
          "value": 4
        },
        {
          "label": "Other",
          "value": 5
        }
      ],
      schoolList: [
        {
          "label": "院校(在校学生或教师)",
          "value": 2
        }
      ],
      seList: [
        {
          "label": "Doctorate",
          "value": "博士研究生"
        },
        {
          "label": "Master",
          "value": "硕士研究生"
        },
        {
          "label": "Bachelor",
          "value": "本科或大专"
        },
        {
          "label": "GED",
          "value": "高中或中专"
        },
        {
          "label": "Other",
          "value": "其他"
        }
      ],
      sizeList: [
        {
          "label": "H7",
          "value": "0 - 50 人"
        },
        {
          "label": "H8",
          "value": "50 - 200 人"
        },
        {
          "label": "H9",
          "value": "200 - 500 人"
        },
        {
          "label": "H10",
          "value": "500 - 1000 人"
        },
        {
          "label": "H11",
          "value": "1000 人以上"
        }
      ],
      designDirectonList: [
        {
          "label": "平面设计",
          "value": "平面设计"
        },
        {
          "label": "工业设计",
          "value": "工业设计"
        },
        {
          "label": "交互设计",
          "value": "交互设计"
        },
        {
          "label": "包装设计",
          "value": "包装设计"
        },
        {
          "label": "互联网设计",
          "value": "互联网设计"
        },
        {
          "label": "多媒体设计",
          "value": "多媒体设计"
        }
      ],
      userInfo: {},
      org_job: [],
      productionType: [],
      productionIDC: null,
      showProductionList: true,
      showJiangnanList: true,
      jiangnanWork: null
    }
  },
  created () {
    if (!this.$Cookies.get('cecc-user')) {
      this.$router.push({
        name: 'login',
        params: {
          from: this.$route.params.from
        }
      })
    }
    if (this.$route.params.from === 'IDCRole') {
      this.activedIndex = 2;
    }
    if (this.$route.params.from === 'IDC') {
      this.activedIndex = 3;
      this.productionType.push(this.$route.params.type);
    }
    if (this.$route.params.from === 'jiangnan') {
      this.activedIndex = 5;
    }
    if (this.$route.params.from === 'moreDemands') {
      this.activedIndex = 6;
    }
    this.$api.getUser_detail().then(res => {
      if (res.status === 2) {
        this.missingInformation = true;
      }
      this.userInfo = res;
      this.$watch('userInfo.org_type', () => {
        this.userInfo.title1 = '';
        this.userInfo.title2 = '';
        this.userInfo.org_name = '';
      }, {
        deep: false
      })
    }).catch(error => {
      if (error.response.status === 403) {
        this.$router.push({
          name: 'login'
        })
      }
    })
  },
  mounted () {
  },
  computed: {
    saveInfoFlag () {
      return this.$Cookies.get('cecc-user') && JSON.parse(this.$Cookies.get('cecc-user')).status !== 2;
    },
    showJiangNan () {
      return this.$Cookies.get('cecc-lang') !== 'en';
    }
  },
  watch: {
    org_job () {
      if (this.org_job.length > 1) {
        this.org_job.shift();
      }
    }
  },
  methods: {
    changePassword () {
      this.changePasswordModal = true;
    },
    saveUserInfo () {
      if (!this.moreTips.includes('agree')) {
        this.$message.warning(this.$t('tips2'));
        return;
      }
      if (!this.userInfo.first_name) {
        this.$message.warning(this.$t('firstName'));
        return;
      }
      if (!this.userInfo.last_name) {
        this.$message.warning(this.$t('lastName'));
        return;
      }
      if (!this.userInfo.mobile) {
        this.$message.warning(this.$t('enterTelPhone'));
        return;
      }
      if (!this.userInfo.country) {
        this.$message.warning(this.$t('selectRegion'));
        return;
      }
      if (!this.userInfo.org_type || !this.userInfo.org_name) {
        this.$message.warning(this.$t('identitySelect'));
        return;
      }
      if (this.moreTips.includes('submit')) {
        this.userInfo.subscribed = true;
      }
      this.userInfo.org_job = this.userInfo[0];
      this.userInfo.full_name = this.userInfo.first_name + this.userInfo.last_name;
      this.$api.postUser_detail(this.userInfo).then(() => {
        this.infoModal = true;
      })
    },
    changeModal (index) {
      if (!this.showJiangNan && index === 5) return;
      this.activedIndex = index;
    },
    updateInformation () {
      this.missingInformation = false;
    },
    changePsd () {
      if (!this.oldPassword.trim() || !this.newPassword.trim() || !this.newPassword1.trim()) {
        this.$message.error(this.$t('P12'));
        return;
      }
      if (this.newPassword.trim() !== this.newPassword1.trim()) {
        this.$message.error(this.$t('P13'));
        return;
      }
      this.$api.postChange_password({
        old_password: this.oldPassword.trim(),
        new_password: this.newPassword.trim()
      }).then(() => {
        this.$message.success(this.$t('P14'));
        this.$Cookies.remove('cecc-user');
        this.$router.push({
          name: 'login'
        })
      })
    },
    toSignUp () {
      this.activedIndex = 3;
    },
    logout () {
      this.$api.postLogout().then(() => {
        this.$Cookies.remove('cecc-user');
        this.$router.go(0);
      })
    },
    help () {
      if (this.$Cookies.get('cecc-lang') === 'en') {
        window.open(window.location.origin + '/pdf/2020_help_en.pdf');
      } else {
        window.open(window.location.origin + '/pdf/2020_help.pdf');
      }
    },
    toProduction (item) {
      this.showProductionList = false;
      // this.activedIndex = 3;
      this.productionIDC = item;
    },
    toJiangnan (item) {
      this.showJiangnanList = false;
      this.jiangnanWork = item;
    },
    backList () {
      this.showProductionList = true;
    },
    backJiangnanList () {
      this.showJiangnanList = true;
    }
  },
  components: {
    CommonFooter,
    IDCAward,
    PersonCenterProduction,
    JiangNan,
    IDCCertificate,
    enterpriseDemand,
    Notice,
    productionList,
    jiangnanList
  }
}
</script>

<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      display: flex;
      justify-content: flex-start;
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      height: 131px;
      margin-top: 10px;
      background: #0f1118;
      &-header {
        width: 80%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        justify-content: space-between;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          display: flex;
          &-left {
            width: 4px;
            height: 20px;
            background: rgba(85, 119, 224, 1);
            border-radius: 5px;
          }
          &-right {
            font-size: 18px;
            font-weight: 400;
            color: rgba(85, 119, 224, 1);
            margin-left: 10px;
          }
        }
        &-right {
          display: flex;
          &-left {
            width: 100px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            border: 1px solid #fff;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      &-item-flex {
        display: flex;
        line-height: 30px;
        margin-top: 20px;
        p {
          margin: 0;
        }
        &-left {
          width: 20.6%;
          p.person-center-wrapper-selfInfo-item-flex-left-main::before {
            content: " · ";
          }
        }
        &-right {
          width: 79%;
          text-align: left;
          margin-left: 1px;
          display: flex;
          .person-center-wrapper-selfInfo-item-flex-right-item {
            width: 100px;
            height: 100px;
            background: rgba(15, 17, 24, 1);
            border: 1px solid rgba(222, 230, 255, 0.3);
            text-align: center;
            line-height: 100px;
            margin-left: 10px;
          }
        }
      }
      &-container {
        margin-top: 20px;
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  .missing-information {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(7, 9, 17, 0.8);
    z-index: 12;
    &-container {
      width: 460px;
      height: 240px;
      background: rgba(23, 25, 32, 1);
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-close {
        width: 18px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        margin: 0;
        text-align: center;
        &:nth-child(2) {
          margin: 30px 0;
        }
      }
    }
    .sure-button {
      width: 140px;
      height: 40px;
      margin: 0 auto;
      text-align: center;
      line-height: 40px;
      letter-spacing: 0;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 29px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
  }
  /deep/.el-textarea__inner {
    border: 1px solid #999;
    border-radius: 0px;
    background-color: #0f1118;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .avatar-uploader {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .button {
    width: 140px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    margin: 30px auto 0;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 18px;
    cursor: pointer;
  }
  .button-container {
    width: 50%;
    margin: 0 auto;
    display: flex;
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-header">
          <div class="person-center-wrapper-selfInfo-header-left">
            <div class="person-center-wrapper-selfInfo-header-left-left">
            </div>
            <div class="person-center-wrapper-selfInfo-header-left-right">
              {{ $t('A1') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-header-right">
            <div class="person-center-wrapper-selfInfo-header-right-left"
                 @click="backList">
              {{ $t('moreM') }}
            </div>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="productionTypeCopy">
            <el-checkbox :checked="productionTypeCopy[0] === 0"
                         label="1"> {{ $t('A2') }} </el-checkbox>
            <el-checkbox :checked="productionTypeCopy[0] === 1"
                         label="2"> {{ $t('A3') }} </el-checkbox>
            <el-checkbox :checked="productionTypeCopy[0] === 2"
                         label="3"> {{ $t('A4') }} </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <template v-if="productionTypeCopy[0] == 2">
        <design-startup :productionIDCI="productionIDC"></design-startup>
      </template>
      <template v-else-if="productionTypeCopy[0] == 3">
        <jiang-nan :isJiangNan="false"
                   :productionIDCI="productionIDC"></jiang-nan>
      </template>
      <template v-else>
        <div class="person-center-wrapper-selfInfo">
          <div class="person-center-wrapper-selfInfo-header">
            <div class="person-center-wrapper-selfInfo-header-left">
              <div class="person-center-wrapper-selfInfo-header-left-left">
              </div>
              <div class="person-center-wrapper-selfInfo-header-left-right">
                <!-- {{ $t('A1') }} -->
                请选择参赛赛区
              </div>
            </div>
            <div class="person-center-wrapper-selfInfo-header-right">
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <el-select v-model="type1Obj.zone"
                       clearable
                       placeholder="请选择参赛赛区"
                       :style="{width: '255px'}">
              <el-option v-for="item in zoneList"
                         :key="item.name"
                         :label="item.name"
                         :value="item.name">
              </el-option>
            </el-select>
            <el-input v-model="tempZone"
                      @click="changeZone"
                      v-if="type1Obj.zone === '其他'"
                      :style="{width: '120px', marginLeft: '12px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{ $t('A5') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <el-checkbox-group v-model="productionType1">
              <el-checkbox label="文创设计"> {{ $t('A6') }}</el-checkbox>
              <el-checkbox label="时尚设计"> {{ $t('A7') }}</el-checkbox>
              <el-checkbox label="工业设计(含产品设计、包装设计、视觉设计等)"> {{ $t('A8') }}</el-checkbox>
              <el-checkbox label="创新设计(含数字设计、新材料设计等)"> {{ $t('A9') }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <!-- <div class="person-center-wrapper-selfInfo"
             v-if="$Cookies.get('cecc-lang') !== 'en'">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              企业命题赛道
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <el-select v-model="type1Obj.demand_id"
                       clearable
                       placeholder="请选择企业命题赛道"
                       :style="{width: '255px'}">
              <el-option v-for="item in demandList"
                         :key="item.id"
                         :label="item.title"
                         :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div> -->
        <div class="person-center-wrapper-selfInfo"
             :style="{height: '100%', paddingBottom: '22px'}">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{ $t('A10') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <el-checkbox-group v-model="productionType2">
              <el-checkbox label="仅概念">{{ $t('A11') }}</el-checkbox>
              <el-checkbox label="DEMO/模型">{{ $t('A12') }}</el-checkbox>
              <el-checkbox label="已上市">{{ $t('A13') }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left"
                 :style="{width: '24%'}">
              {{ $t('A14') }}
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-checkbox-group v-model="shangshi">
                <el-checkbox label="产业转化">{{ $t('A15') }}</el-checkbox>
                <el-checkbox label="线上推广">{{ $t('A16') }}</el-checkbox>
                <el-checkbox label="线下渠道">{{ $t('A17') }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo"
             :style="{height: '269px'}">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{ $t('A18') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A19') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input v-model="type1Obj.name"
                        :placeholder="$t('H84')"
                        :style="{width: '255px'}"></el-input>
              <el-input v-model="type1Obj.name1"
                        :placeholder="$t('H85')"
                        v-if="$Cookies.get('cecc-lang') === 'zh'"
                        :style="{width: '255px', marginLeft: '20px'}"></el-input>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A20') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input v-model="type1Obj.type"
                        :placeholder="$t('A20_1')"
                        :style="{width: '255px'}"></el-input>
              <el-input v-model="type1Obj.type1"
                        :placeholder="$t('H87')"
                        v-if="$Cookies.get('cecc-lang') === 'zh'"
                        :style="{width: '255px', marginLeft: '20px'}"></el-input>
            </div>
          </div>
          <div class="button"
               v-if="type1Obj.draft"
               @click="saveInfo">
            {{ $t('A21') }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo"
             :style="{height: '408px'}">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{ $t('A22') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A23') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input type="textarea"
                        :rows="2"
                        :placeholder="$t('H60')"
                        v-model="type1Obj.dingwei">
              </el-input>
            </div>
          </div>
          <!-- <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A23') }} (英文)
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input type="textarea"
                        :rows="2"
                        placeholder="英文，50字内，用一句话描述您的作品。"
                        v-model="type1Obj.dingwei1">
              </el-input>
            </div>
          </div> -->
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A24') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input type="textarea"
                        :rows="6"
                        :placeholder="$t('H61')"
                        v-model="type1Obj.chuangxin">
              </el-input>
            </div>
          </div>
          <!-- <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A24') }} (英文)
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input type="textarea"
                        :rows="6"
                        placeholder="英文，300字内，原则上不要超过三个主要设计创新点。"
                        v-model="type1Obj.chuangxin1">
              </el-input>
            </div>
          </div> -->
          <div class="button"
               v-if="type1Obj.draft"
               @click="saveInfo">
            {{ $t('A21') }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo"
             :style="{height: '587px'}">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{ $t('A25') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A26') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              {{ $t('A27') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-upload class="avatar-uploader"
                         :action="imgUrl1"
                         :show-file-list="false"
                         :on-success="onSuccess"
                         :on-remove="handleRemove">
                <img v-if="type1Obj.imgHoster"
                     class="avatar-uploader"
                     :src="'/api/static/'+type1Obj.imgHoster">
                <i v-else
                   class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A28') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              {{ $t('A29') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-upload class="avatar-uploader"
                         :action="imgUrl"
                         :show-file-list="false"
                         :on-success="onSuccess1"
                         :on-remove="handleRemove">
                <img v-if="type1Obj.previewUrl"
                     class="avatar-uploader"
                     :src="'/api/static/'+type1Obj.previewUrl">
                <i v-else
                   class="el-icon-plus"></i>
              </el-upload>
              <el-upload class="avatar-uploader"
                         :action="imgUrl"
                         :show-file-list="false"
                         :on-success="onSuccess2"
                         :on-remove="handleRemove"
                         :style="{marginLeft: '18px'}">
                <img v-if="type1Obj.previewUrl1"
                     class="avatar-uploader"
                     :src="'/api/static/'+type1Obj.previewUrl1">
                <i v-else
                   class="el-icon-plus"></i>
              </el-upload>
              <el-upload class="avatar-uploader"
                         :action="imgUrl"
                         :show-file-list="false"
                         :on-success="onSuccess3"
                         :on-remove="handleRemove"
                         :style="{marginLeft: '18px'}">
                <img v-if="type1Obj.previewUrl2"
                     class="avatar-uploader"
                     :src="'/api/static/'+type1Obj.previewUrl2">
                <i v-else
                   class="el-icon-plus"></i>
              </el-upload>
              <el-upload class="avatar-uploader"
                         :action="imgUrl"
                         :show-file-list="false"
                         :on-success="onSuccess4"
                         :on-remove="handleRemove"
                         :style="{marginLeft: '18px'}">
                <img v-if="type1Obj.previewUrl3"
                     class="avatar-uploader"
                     :src="'/api/static/'+type1Obj.previewUrl3">
                <i v-else
                   class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p>
                {{ $t('A30') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input v-model="type1Obj.videoUrl"
                        :placeholder="$t('H96')"
                        :style="{width: '780px'}"></el-input>
            </div>
          </div>
          <div class="button"
               v-if="type1Obj.draft"
               @click="saveInfo">
            {{ $t('A21') }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo"
             :style="{height: '534px'}">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              {{ $t('A31') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A32') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-select v-model="type1Obj.owner"
                         filterable
                         :placeholder="$t('H65')"
                         :style="{width: '255px'}">
                <el-option v-for="item in ownerType"
                           :key="item.value"
                           :label="$t(item.label)"
                           :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="type1Obj.ownerCountry"
                         filterable
                         :placeholder="$t('H64')"
                         :style="{width: '255px', marginLeft: '20px'}">
                <el-option v-for="item in citys"
                           :key="item.value"
                           :label="$Cookies.get('cecc-lang') === 'en' ? item.shortName : item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A33') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-select v-model="type1Obj.designer"
                         filterable
                         :placeholder="$t('H65')"
                         :style="{width: '255px'}">
                <el-option v-for="item in ownerType"
                           :key="item.value"
                           :label="$t(item.label)"
                           :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="type1Obj.designerCountry"
                         filterable
                         :placeholder="$t('H64')"
                         :style="{width: '255px', marginLeft: '20px'}">
                <el-option v-for="item in citys"
                           :key="item.value"
                           :label="$Cookies.get('cecc-lang') === 'en' ? item.shortName : item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p class="person-center-wrapper-selfInfo-item-flex-left-main">
                {{ $t('A34') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input v-model="type1Obj.n1"
                        :placeholder="$t('H62')"
                        :style="{width: '255px'}"></el-input>
              <el-input v-model="type1Obj.j1"
                        :placeholder="$t('H63')"
                        :style="{width: '255px', marginLeft: '20px'}"></el-input>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p>
                {{ $t('A35') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input v-model="type1Obj.n2"
                        :placeholder="$t('H62')"
                        :style="{width: '255px'}"></el-input>
              <el-input v-model="type1Obj.j2"
                        :placeholder="$t('H63')"
                        :style="{width: '255px', marginLeft: '20px'}"></el-input>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p>
                {{ $t('A36') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input v-model="type1Obj.n3"
                        :placeholder="$t('H62')"
                        :style="{width: '255px'}"></el-input>
              <el-input v-model="type1Obj.j3"
                        :placeholder="$t('H63')"
                        :style="{width: '255px', marginLeft: '20px'}"></el-input>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p>
                {{ $t('A37') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input v-model="type1Obj.n4"
                        :placeholder="$t('H62')"
                        :style="{width: '255px'}"></el-input>
              <el-input v-model="type1Obj.j4"
                        :placeholder="$t('H63')"
                        :style="{width: '255px', marginLeft: '20px'}"></el-input>
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
            <div class="person-center-wrapper-selfInfo-item-flex-left">
              <p>
                {{ $t('A38') }}
              </p>
            </div>
            <div class="person-center-wrapper-selfInfo-item-flex-right">
              <el-input v-model="type1Obj.n5"
                        :placeholder="$t('H62')"
                        :style="{width: '255px'}"></el-input>
              <el-input v-model="type1Obj.j5"
                        :placeholder="$t('H63')"
                        :style="{width: '255px', marginLeft: '20px'}"></el-input>
            </div>
          </div>
          <div class="button-container"
               v-if="type1Obj.draft">
            <div class="button"
                 @click="saveInfo">
              {{ $t('A21') }}
            </div>
            <div class="button"
                 @click="confirmInfo">
              {{ $t('Q38') }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="missing-information"
         v-if="submit">
      <div class="missing-information-container">
        <img src="../assets/close.png"
             alt=""
             class="missing-information-container-close"
             @click="submit = false">
        <p>
          {{ $t('tips') }}
        </p>
        <p>
          {{ $t('Q39') }}
        </p>
        <div class="sure-button"
             @click="saveInfoCheck"
             :style="{marginTop: '40px'}">
          {{$t('confirm')}}
        </div>
      </div>
    </div>
    <Common-footer :style="{background: '#0F1118'}"></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';
import JiangNan from './jiang-nan.vue';
import designStartup from './design-start.vue';
import cityJSON from './city.json'

export default {
  name: 'IDC-award',
  props: {
    productionType: {
      type: Array,
      default () { return [] }
    },
    productionIDC: {
      type: Object,
      default () { return {} }
    },
  },
  data () {
    return {
      submit: false,
      demandList: [],
      dialogImageUrl: '',
      productionTypeCopy: [],
      productionType1: [],
      productionType2: [],
      citys: cityJSON.citys,
      ownerType: [{
        value: '个人',
        label: 'H66'
      }, {
        value: '单位',
        label: 'H67'
      }],
      shangshi: [],
      type1Obj: {
        zone: '',
        draft: true,
        demand_id: null,
        name: '',
        name1: '',
        type: '',
        type1: '',
        dingwei: '',
        dingwei1: '',
        chuangxin: '',
        chuangxin1: '',
        videoUrl: '',
        owner: '',
        ownerCountry: '',
        designer: '',
        designerCountry: '',
        n1: '',
        j1: '',
        n2: '',
        j2: '',
        n3: '',
        j3: '',
        n4: '',
        j4: '',
        n5: '',
        j5: '',
        imgHoster: '',
        previewUrl: '',
        previewUrl1: '',
        previewUrl2: '',
        previewUrl3: '',
      },
      imgUrl1: '/api/upload_image?category=work_cover',
      imgUrl: '/api/upload_image?category=design_preview',
      zoneList: [],
      tempZone: ''
    }
  },
  created () {
    this.productionTypeCopy = this.productionType;
    if (this.productionIDC) {
      this.productionTypeCopy = this.productionIDC.module === "design" ? ['1'] : this.productionIDC.module === "product" ? ['2'] : ['3'];
      this.type1Obj = this.productionIDC.detail;
      this.type1Obj['draft'] = this.productionIDC.draft;
      this.productionType1 = [this.productionIDC.product_type];
      this.productionType2 = [this.productionIDC.product_status];
      this.shangshi = this.productionIDC.product_need;
    }
    this.$api.getDesign_demand_list_simple().then(res => {
      this.demandList = res.demands
    })
    this.$api.getZones().then(res => {
      this.zoneList = res.zones
    })

    // this.$api.getDesign_work().then(res => {
    //   // if (res.module != "design") {
    //   //   this.$message.error(this.$t('P16'));
    //   // }
    //   if (res.id) {
    //     this.productionTypeCopy = res.module === "design" ? ['1'] : res.module === "product" ? ['2'] : ['3'];
    //     this.type1Obj = res.detail;
    //     this.productionType1 = [res.product_type];
    //     this.productionType2 = [res.product_status];
    //     this.shangshi = res.product_need;
    //   }
    // })
  },
  mounted () {
  },
  computed: {
  },
  watch: {
    productionTypeCopy () {
      if (this.productionTypeCopy.length > 1) {
        this.productionTypeCopy.shift();
        if (this.productionTypeCopy[0] == 1) {
          if (this.productionType && this.productionIDC && this.productionIDC.module != "design") {
            this.$message.error(this.$t('P16'));
          }
        }
      }
    },
    productionType1 () {
      if (this.productionType1.length > 1) {
        this.productionType1.shift();
      }
    },
    productionType2 () {
      if (this.productionType2.length > 1) {
        this.productionType2.shift();
      }
    }
  },
  methods: {
    onSuccess (file) {
      this.type1Obj.imgHoster = file.filename;
    },
    onSuccess1 (file) {
      this.type1Obj.previewUrl = file.filename;
    },
    onSuccess2 (file) {
      this.type1Obj.previewUrl1 = file.filename;
    },
    onSuccess3 (file) {
      this.type1Obj.previewUrl2 = file.filename;
    },
    onSuccess4 (file) {
      this.type1Obj.previewUrl3 = file.filename;
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    backList () {
      this.$emit('backList');
    },
    confirmInfo () {
      if (!this.productionType1[0] || !this.productionType2[0] || !this.type1Obj.name || !this.type1Obj.type ||
        !this.type1Obj.owner || !this.type1Obj.ownerCountry || !this.type1Obj.designerCountry
        || !this.type1Obj.n1) {
        this.$message.warning(this.$t('Q30'));
        return;
      }
      this.submit = true;
    },
    saveInfoCheck () {
      let temp = {
        module: 'design',
        draft: false,
        product_type: this.productionType1[0],
        product_status: this.productionType2[0],
        product_need: this.shangshi,
        name: this.type1Obj.name,
        work_attribute: this.type1Obj.type,
        owner_type: this.type1Obj.owner,
        owner_country: this.type1Obj.ownerCountry,
        author_type: this.type1Obj.designer,
        author_country: this.type1Obj.designerCountry,
        main_author: this.type1Obj.n1,
        detail: this.type1Obj,
        demand_id: this.type1Obj.demand_id || null,
        images: {
          cover: this.type1Obj.imgHoster,
          detail1: this.type1Obj.previewUrl,
          detail2: this.type1Obj.previewUrl1,
          detail3: this.type1Obj.previewUrl2,
          detail4: this.type1Obj.previewUrl3
        },
        zone: this.type1Obj.zone,
        year: 2021
      };
      if (this.productionIDC) {
        this.$api.putDesign_work({
          id: this.productionIDC.id,
          ...temp
        }).then(res => {
          this.type1Obj = res.detail;
          this.type1Obj['draft'] = res.draft;
          this.productionType1 = [res.product_type];
          this.productionType2 = [res.product_status];
          this.shangshi = res.product_need;
          this.submit = false;
          this.$message.success(this.$t('P15'));
        })
      } else {
        this.$api.postDesign_work(temp).then(res => {
          this.type1Obj = res.detail;
          this.type1Obj['draft'] = res.draft;
          this.productionType1 = [res.product_type];
          this.productionType2 = [res.product_status];
          this.shangshi = res.product_need;
          this.submit = false;
          this.$message.success(this.$t('Q37'));
        })
      }
    },
    saveInfo () {
      let temp = {
        module: 'design',
        product_type: this.productionType1[0],
        product_status: this.productionType2[0],
        product_need: this.shangshi,
        name: this.type1Obj.name,
        work_attribute: this.type1Obj.type,
        owner_type: this.type1Obj.owner,
        owner_country: this.type1Obj.ownerCountry,
        author_type: this.type1Obj.designer,
        author_country: this.type1Obj.designerCountry,
        main_author: this.type1Obj.n1,
        detail: this.type1Obj,
        demand_id: this.type1Obj.demand_id || null,
        images: {
          cover: this.type1Obj.imgHoster,
          detail1: this.type1Obj.previewUrl,
          detail2: this.type1Obj.previewUrl1,
          detail3: this.type1Obj.previewUrl2,
          detail4: this.type1Obj.previewUrl3
        },
        zone: this.type1Obj.zone,
        year: 2021
      };
      if (this.productionIDC) {
        this.$api.putDesign_work({
          id: this.productionIDC.id,
          ...temp
        }).then(() => {
          this.$message.success(this.$t('P15'));
        })
      } else {
        this.$api.postDesign_work(temp).then(() => {
          this.$message.success(this.$t('Q37'));
        })
      }
    },
    changeZone () {
      this.type1Obj.zone = this.tempZone;
    }
  },
  components: {
    CommonFooter,
    JiangNan,
    designStartup
  }
}
</script>

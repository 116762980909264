<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  color: #dee6ff;
  position: relative;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      display: flex;
      justify-content: flex-start;
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      padding-bottom: 28px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      &-item-flex {
        display: flex;
        line-height: 30px;
        margin-top: 20px;
        p {
          margin: 0;
        }
        &-left {
          width: 20.6%;
          p.person-center-wrapper-selfInfo-item-flex-left-main::before {
            content: " · ";
          }
        }
        &-right {
          width: 79%;
          text-align: left;
          margin-left: 1px;
          display: flex;
          .person-center-wrapper-selfInfo-item-flex-right-item {
            width: 100px;
            height: 100px;
            background: rgba(15, 17, 24, 1);
            border: 1px solid rgba(222, 230, 255, 0.3);
            text-align: center;
            line-height: 100px;
            margin-left: 10px;
          }
        }
      }
      &-container {
        margin-top: 20px;
      }
    }
    .back {
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      border: 1px solid #fff;
      border-radius: 4px;
      cursor: pointer;
      position: absolute;
      top: 32px;
      right: 80px;
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  .missing-information {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(7, 9, 17, 0.8);
    z-index: 12;
    &-container {
      width: 460px;
      height: 240px;
      background: rgba(23, 25, 32, 1);
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-close {
        width: 18px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        margin: 0;
        text-align: center;
        &:nth-child(2) {
          margin: 30px 0;
        }
      }
    }
    .sure-button {
      width: 140px;
      height: 40px;
      margin: 0 auto;
      text-align: center;
      line-height: 40px;
      letter-spacing: 0;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 29px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
  }
  /deep/.el-textarea__inner {
    border: 1px solid #999;
    border-radius: 0px;
    background-color: #0f1118;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .avatar-uploader {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .button-wrapper {
    width: 50%;
    margin: 0 auto;
    display: flex;
  }
  .button {
    width: 140px;
    height: 36px;
    text-align: center;
    overflow: hidden;
    line-height: 36px;
    margin: 30px auto 0;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 18px;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            <!-- {{ $t('A1') }} -->
            请选择参赛赛区
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-select v-model="brandObj.zone"
                     clearable
                     placeholder="请选择参赛赛区"
                     :style="{width: '255px'}">
            <el-option v-for="item in zoneList"
                       :key="item.name"
                       :label="item.name"
                       :value="item.name">
            </el-option>
          </el-select>
          <el-input v-model="tempZone"
                    @click="changeZone"
                    v-if="brandObj.zone === '其他'"
                    :style="{width: '120px', marginLeft: '12px'}"></el-input>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('B22')}}
          </div>
        </div>
        <div class="back"
             v-if="isJiangNan"
             @click="backList">
          作品管理
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="qiyeleixing">
            <el-checkbox label="工厂">{{$t('B23')}}</el-checkbox>
            <el-checkbox label="品牌方">{{$t('B24')}}</el-checkbox>
            <el-checkbox label="经销商">{{$t('B25')}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('B26')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B27')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="brandObj.name_zh"
                      :placeholder="$t('H92')"
                      :style="{width: '255px'}"></el-input>
            <el-select v-model="brandObj.time"
                       filterable
                       :placeholder="$t('H93')"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in timeList"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B28')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="brandObj.ownerName"
                      :placeholder="$t('H94')"
                      :style="{width: '255px'}"></el-input>
            <el-select v-model="brandObj.money"
                       filterable
                       :placeholder="$t('H95')"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in moneyList"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B29')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-select v-model="brandObj.nashuiren"
                       filterable
                       :placeholder="$t('H91')"
                       :style="{width: '255px'}">
              <el-option v-for="item in nashuirenList"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="brandObj.logo"
                       filterable
                       :placeholder="$t('H90')"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in logoList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B30')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="brandObj.provide"
                      :placeholder="$t('H88')"
                      :style="{width: '255px'}"></el-input>
            <el-select v-model="brandObj.get"
                       filterable
                       :placeholder="$t('H89')"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in moneyList"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B31')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <template v-if="$Cookies.get('cecc-lang') !== 'en'">
              <el-cascader :options="options"
                           v-model="brandObj.city"
                           @change="handleChange"
                           :style="{width: '255px',lineHeight: '30px'}">
              </el-cascader>
            </template>
            <template v-else>
              <el-select v-model="brandObj.city"
                         filterable
                         :placeholder="$t('selectRegion')"
                         :style="{width: '255px',lineHeight: '30px'}">
                <el-option v-for="item in citys"
                           :key="item.value"
                           :label="item.shortName"
                           :value="item.value">
                </el-option>
              </el-select>
            </template>
            <el-input v-model="brandObj.local"
                      :placeholder="$t('H83')"
                      :style="{width: '255px', marginLeft: '20px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('contactPhone')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="brandObj.contactPhone"
                      :placeholder="$t('enterContactPhone')"
                      :style="{width: '255px'}"></el-input>
          </div>
        </div>
        <div class="button"
             v-if="brandObj.draft"
             @click="saveInfo">
          {{ $t('A21') }}
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{ $t('B32') }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="pingpaichanping">
            <el-checkbox label="消费名品">{{ $t('B33') }}</el-checkbox>
            <el-checkbox label="文旅名品">{{ $t('B34') }}</el-checkbox>
            <el-checkbox label="农副产品">{{ $t('B35') }}</el-checkbox>
            <el-checkbox label="其他">{{ $t('B36') }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{ $t('B37') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-select v-model="brandObj.brandType"
                       filterable
                       :placeholder="$t('B38')"
                       :style="{width: '530px'}">
              <el-option v-for="item in brandTypeList"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('B46')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="pingpaixuqiu">
            <el-checkbox label="产业转化">{{$t('B47')}}</el-checkbox>
            <el-checkbox label="线上推广">{{$t('B48')}}</el-checkbox>
            <el-checkbox label="线下渠道">{{$t('B49')}}</el-checkbox>
            <el-checkbox label="品牌与设计赋能">{{$t('B50')}}</el-checkbox>
            <el-checkbox label="技术与供应链渠道">{{$t('B51')}}</el-checkbox>
            <el-checkbox label="海外市场拓展">{{$t('B51_1')}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <template v-if="pingpaixuqiu.includes('线下渠道')">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              开拓线下业务需求
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <el-checkbox-group v-model="xianxia">
              <el-checkbox label="xianxia1">办公、连锁开店：线下开店（商业）、办公选址（上海）</el-checkbox>
              <el-checkbox label="xianxia2">基金+基地模式：落户上海虹桥核心基地（开设公司）及返税服务、政府专项扶持基金及补贴、产业基金对接</el-checkbox>
              <el-checkbox label="xianxia3">联合办公+业务行政代理服务</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template v-if="pingpaixuqiu.includes('海外市场拓展')">
          <div class="person-center-wrapper-selfInfo-title">
            <div class="person-center-wrapper-selfInfo-title-left">
            </div>
            <div class="person-center-wrapper-selfInfo-title-right">
              海外市场拓展需求
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-item">
            <el-checkbox-group v-model="haiwai">
              <el-checkbox label="haiwai1">海外投资项目介绍/对接</el-checkbox>
              <el-checkbox label="haiwai2">海外办事处/公司注册，及日常运营支持</el-checkbox>
              <el-checkbox label="haiwai3">海外市场线上线下推广</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>

        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('B52')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <el-checkbox-group v-model="shifou1">
              <el-checkbox label="1">{{$t('B56')}}</el-checkbox>
              <el-checkbox label="2">{{$t('B55')}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-select v-model="brandObj.onlineGet"
                       filterable
                       :placeholder="$t('B53')"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in moneyList"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-select v-model="brandObj.offlineGet"
                       filterable
                       :placeholder="$t('B54')"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in moneyList"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('B58')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B59')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input type="textarea"
                      :rows="2"
                      :placeholder="$t('H73')"
                      v-model="brandObj.brandName">
            </el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B60')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input type="textarea"
                      :rows="2"
                      :placeholder="$t('H74')"
                      v-model="brandObj.introduce">
            </el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B61')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input type="textarea"
                      :rows="6"
                      :placeholder="$t('H75')"
                      v-model="brandObj.explain">
            </el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B62')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input type="textarea"
                      :rows="6"
                      :placeholder="$t('H76')"
                      v-model="brandObj.adv">
            </el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B63')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            {{$t('B64')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-upload class="avatar-uploader"
                       :action="imgUrl1"
                       :show-file-list="false"
                       :on-success="onSuccess">
              <img v-if="brandObj.logoImg"
                   class="avatar-uploader"
                   :src="'/api/static/'+brandObj.logoImg">
              <i v-else
                 class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B65')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            {{$t('B66')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-upload class="avatar-uploader"
                       :action="imgUrl"
                       :show-file-list="false"
                       :on-success="onSuccess1">
              <img v-if="brandObj.previewUrl"
                   class="avatar-uploader"
                   :src="'/api/static/'+brandObj.previewUrl">
              <i v-else
                 class="el-icon-plus"></i>
            </el-upload>
            <el-upload class="avatar-uploader"
                       :style="{marginLeft: '18px'}"
                       :action="imgUrl"
                       :show-file-list="false"
                       :on-success="onSuccess2">
              <img v-if="brandObj.previewUrl1"
                   class="avatar-uploader"
                   :src="'/api/static/'+brandObj.previewUrl1">
              <i v-else
                 class="el-icon-plus"></i>
            </el-upload>
            <el-upload class="avatar-uploader"
                       :style="{marginLeft: '18px'}"
                       :action="imgUrl"
                       :show-file-list="false"
                       :on-success="onSuccess3">
              <img v-if="brandObj.previewUrl2"
                   class="avatar-uploader"
                   :src="'/api/static/'+brandObj.previewUrl2">
              <i v-else
                 class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p>
              {{$t('B67')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="brandObj.videoUrl"
                      :placeholder="$t('H57')"
                      :style="{width: '780px'}"></el-input>
          </div>
        </div>
        <div class="button-wrapper">
          <!-- <div class="button"
               v-if="brandObj.draft"
               @click="saveInfo">
            {{ $t('A21') }}
          </div> -->
          <div class="button"
               v-if="brandObj.draft"
               @click="submit = true">
            {{ $t('Q38') }}
          </div>
          <div class="button"
               @click="preViewInfo">
            {{ $t('B68') }}
          </div>
        </div>
      </div>
      <!-- <div class="person-center-wrapper-selfInfo"
           v-if="isJiangNan && $Cookies.get('cecc-lang') !== 'en' && brandObj.draft">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            如果您申报的是原创名品，并且需要渠道拓展支持，请选择参与IDC（原创名品模块）大赛，点击可一键同步资料。
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="shifou">
            <el-checkbox label="1">{{$t('B55')}}</el-checkbox>
            <el-checkbox label="2">{{$t('B57')}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="button"
             v-if="brandObj.draft"
             @click="update">
          {{ $t('confirm') }}
        </div>
      </div> -->
    </div>
    <div class="missing-information"
         v-if="submit">
      <div class="missing-information-container">
        <img src="../assets/close.png"
             alt=""
             class="missing-information-container-close"
             @click="submit = false">
        <p>
          {{ $t('tips') }}
        </p>
        <p>
          {{ $t('Q39') }}
        </p>
        <div class="sure-button"
             @click="saveInfoConfirm"
             :style="{marginTop: '40px'}">
          {{$t('confirm')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityJSON from './city.json';
import { provinceAndCityData } from 'element-china-area-data';

export default {
  name: 'jiangnan',
  props: {
    isJiangNan: {
      type: Boolean,
      default () { return true }
    },
    productionIDCI: {
      type: Object,
      default () { return {} }
    },
    jiangnanWork: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      qiyeleixing: [],
      submit: false,
      brandObj: {
        zone: '',
        draft: true,
        contactPhone: '',
        name_zh: '',
        ownerName: '',
        time: '',
        money: '',
        nashuiren: '',
        logo: '',
        provide: '',
        get: '',
        city: '',
        local: '',
        brandType: '',
        onlineGet: '',
        offlineGet: '',
        brandName: '',
        introduce: '',
        explain: '',
        adv: '',
        videoUrl: '',
        logoImg: '',
        previewUrl: '',
        previewUrl1: '',
        previewUrl2: '',
      },
      timeList: [{
        label: 'L20',
        value: '1990以前'
      },
      {
        label: 'L21',
        value: '1990-2000'
      },
      {
        label: 'L22',
        value: '2000-2010'
      },
      {
        label: 'L23',
        value: '2010以后'
      }],
      moneyList: [{
        label: 'H77',
        value: '1000万以下'
      },
      {
        label: 'H78',
        value: '1000-5000万'
      },
      {
        label: 'H79',
        value: '5000万-1亿'
      },
      {
        label: 'H80',
        value: '1亿-10亿'
      },
      {
        label: 'H82',
        value: '10亿以上'
      }],
      nashuirenList: [{
        label: 'B55',
        value: '一般纳税人'
      },
      {
        label: 'B56',
        value: '不是一般纳税人'
      }],
      logoList: [{
        label: 'TM',
        value: 'TM标'
      }, {
        label: 'R',
        value: 'R标'
      }],
      brandTypeList: [{
        label: 'B39',
        value: '消费电子'
      }, {
        label: 'B40',
        value: '服装服饰'
      }, {
        label: 'B41',
        value: '大快消'
      }, {
        label: 'B42',
        value: '文旅用品'
      }, {
        label: 'B43',
        value: '服务'
      }, {
        label: 'B44',
        value: '家装'
      }, {
        label: 'B45',
        value: '其他'
      }],
      pingpaichanping: [],
      pingpaixuqiu: [],
      shifou: [],
      shifou1: [],
      citys: cityJSON.citys,
      options: provinceAndCityData,
      selectedOptions: [],
      imgUrl1: '/api/upload_image?category=logo',
      imgUrl: '/api/upload_image?category=brand_ad',
      xianxia: [],
      haiwai: [],
      zoneList: [],
      tempZone: ''
    }
  },
  created () {
    if (this.isJiangNan) {
      if (this.jiangnanWork) {
        this.brandObj = this.jiangnanWork.detail;
        this.brandObj['draft'] = this.jiangnanWork.draft;
        this.brandObj.id = this.jiangnanWork.id;
        this.qiyeleixing = this.checkIsJSON(this.jiangnanWork.company_type) ? JSON.parse(this.jiangnanWork.company_type) : [this.jiangnanWork.company_type];
        this.pingpaichanping = [this.jiangnanWork.product_type];
        this.pingpaixuqiu = this.jiangnanWork.product_need;
        this.shifou1 = [this.jiangnanWork.detail.shifou1];
        this.haiwai = this.jiangnanWork.detail.haiwai;
        this.xianxia = this.jiangnanWork.detail.xianxia;
      }
      // this.$api.getDesign_product().then(res => {
      //   if (res.items.length > 0) {
      //     this.brandObj = res.items[0].detail;
      //     this.brandObj.id = res.items[0].id;
      //     this.qiyeleixing = [res.items[0].company_type];
      //     this.pingpaichanping = [res.items[0].product_type];
      //     this.pingpaixuqiu = res.items[0].product_need;
      //     this.shifou1 = [res.items[0].detail.shifou1]
      //   }
      // })
    } else {
      if (this.productionIDCI) {
        if (this.productionIDCI.module !== "brand") {
          this.$message.error(this.$t('P16'));
        }
        this.brandObj = this.productionIDCI.detail;
        this.qiyeleixing = this.checkIsJSON(this.productionIDCI.company_type) ? JSON.parse(this.productionIDCI.company_type) : [this.productionIDCI.company_type];
        this.pingpaichanping = [this.productionIDCI.product_type];
        this.pingpaixuqiu = this.productionIDCI.product_need;
        this.haiwai = this.productionIDCI.detail.haiwai;
        this.xianxia = this.productionIDCI.detail.xianxia;
      }
    }
    this.$api.getZones().then(res => {
      this.zoneList = res.zones
    })
  },
  mounted () {
  },
  computed: {
  },
  watch: {
    pingpaichanping () {
      if (this.pingpaichanping.length > 1) {
        this.pingpaichanping.shift();
      }
    },
    shifou () {
      if (this.shifou.length > 1) {
        this.shifou.shift();
      }
    },
    shifou1 () {
      if (this.shifou1.length > 1) {
        this.shifou1.shift();
      }
    }
  },
  methods: {
    onSuccess (file) {
      this.brandObj.logoImg = file.filename;
    },
    onSuccess1 (file) {
      this.brandObj.previewUrl = file.filename;
    },
    onSuccess2 (file) {
      this.brandObj.previewUrl1 = file.filename;
    },
    onSuccess3 (file) {
      this.brandObj.previewUrl2 = file.filename;
    },
    saveInfoConfirm () {
      if (this.isJiangNan) {
        if (this.qiyeleixing.length === 0 || !this.brandObj.name_zh) {
          this.$message.warning(this.$t('tips1'));
          return;
        }
        let temp = {
          draft: false,
          company_type: JSON.stringify(this.qiyeleixing),
          brand_name: this.brandObj.name_zh,
          company_city: this.$Cookies.get('cecc-lang') !== 'en' ? this.brandObj.city[1] : this.brandObj.city,
          company_name: this.brandObj.ownerName,
          product_type: this.pingpaichanping[0],
          product_need: this.pingpaixuqiu,
          detail: {
            ...this.brandObj,
            shifou1: this.shifou1[0]
          },
          images: {
            logo: this.brandObj.logoImg,
            banner1: this.brandObj.previewUrl,
            banner2: this.brandObj.previewUrl1,
            banner3: this.brandObj.previewUrl2
          }
        };
        if (this.brandObj.id) {
          this.$api.putDesign_product({
            id: this.brandObj.id,
            ...temp
          }).then(res => {
            this.brandObj = res.detail;
            this.brandObj['draft'] = res.draft;
            this.brandObj.id = res.id;
            this.qiyeleixing = [res.company_type];
            this.pingpaichanping = [res.product_type];
            this.pingpaixuqiu = res.product_need;
            this.shifou1 = [res.detail.shifou1];
            this.submit = false;
            this.$message.success(this.$t('P15'));
          })
        } else {
          this.$api.postDesign_product(temp).then(res => {
            this.brandObj = res.detail;
            this.brandObj['draft'] = res.draft;
            this.brandObj.id = res.id;
            this.qiyeleixing = JSON.parse(res.company_type);
            this.pingpaichanping = [res.product_type];
            this.pingpaixuqiu = res.product_need;
            this.shifou1 = [res.detail.shifou1];
            this.submit = false;
            this.$message.success(this.$t('Q37'));
          })
        }
      } else {
        let temp = {
          draft: false,
          company_type: JSON.stringify(this.qiyeleixing),
          name: this.brandObj.name_zh,
          company_city: this.$Cookies.get('cecc-lang') !== 'en' ? this.brandObj.city[1] : this.brandObj.city,
          company_name: this.brandObj.ownerName,
          product_type: this.pingpaichanping[0],
          product_need: this.pingpaixuqiu,
          detail: {
            ...this.brandObj,
            shifou1: this.shifou1[0]
          },
          images: {
            logo: this.brandObj.logoImg,
            banner1: this.brandObj.previewUrl,
            banner2: this.brandObj.previewUrl1,
            banner3: this.brandObj.previewUrl2
          },
          module: 'brand',
          zone: this.brandObj.zone,
          year: 2021
        };
        if (this.productionIDCI) {
          this.$api.putDesign_work({
            id: this.productionIDCI.id,
            ...temp
          }).then(res => {
            this.brandObj = res.detail;
            this.brandObj['draft'] = res.draft;
            this.qiyeleixing = JSON.parse(res.company_type);
            this.pingpaichanping = [res.product_type];
            this.pingpaixuqiu = res.product_need;
            this.submit = false;
            this.$message.success(this.$t('P15'));
          })
        } else {
          this.$api.postDesign_work(temp).then(res => {
            this.brandObj = res.detail;
            this.brandObj['draft'] = res.draft;
            this.qiyeleixing = JSON.parse(res.company_type);
            this.pingpaichanping = [res.product_type];
            this.pingpaixuqiu = res.product_need;
            this.submit = false;
            this.$message.success(this.$t('Q37'));
          })
        }
      }
    },
    saveInfo () {
      if (this.isJiangNan) {
        let temp = {
          company_type: JSON.stringify(this.qiyeleixing),
          brand_name: this.brandObj.name_zh,
          company_city: this.$Cookies.get('cecc-lang') !== 'en' ? this.brandObj.city[1] : this.brandObj.city,
          company_name: this.brandObj.ownerName,
          product_type: this.pingpaichanping[0],
          product_need: this.pingpaixuqiu,
          detail: {
            ...this.brandObj,
            shifou1: this.shifou1[0],
            haiwai: this.haiwai,
            xianxia: this.xianxia
          },
          images: {
            logo: this.brandObj.logoImg,
            banner1: this.brandObj.previewUrl,
            banner2: this.brandObj.previewUrl1,
            banner3: this.brandObj.previewUrl2
          }
        };
        if (this.brandObj.id) {
          this.$api.putDesign_product({
            id: this.brandObj.id,
            ...temp
          }).then(() => {
            this.$message.success(this.$t('P15'));
          })
        } else {
          this.$api.postDesign_product(temp).then(res => {
            this.brandObj = res.detail;
            this.brandObj['draft'] = true;
            this.brandObj.id = res.id;
            this.qiyeleixing = JSON.parse(res.company_type);
            this.pingpaichanping = [res.product_type];
            this.pingpaixuqiu = res.product_need;
            this.shifou1 = [res.detail.shifou1];
            this.haiwai = res.detail.haiwai;
            this.xianxia = res.detail.xianxia;
            this.$message.success(this.$t('Q37'));
          })
        }
      } else {
        let temp = {
          company_type: this.qiyeleixing[0],
          name: this.brandObj.name_zh,
          company_city: this.$Cookies.get('cecc-lang') !== 'en' ? this.brandObj.city[1] : this.brandObj.city,
          company_name: this.brandObj.ownerName,
          product_type: this.pingpaichanping[0],
          product_need: this.pingpaixuqiu,
          detail: {
            ...this.brandObj,
            shifou1: this.shifou1[0],
            haiwai: this.haiwai,
            xianxia: this.xianxia
          },
          images: {
            logo: this.brandObj.logoImg,
            banner1: this.brandObj.previewUrl,
            banner2: this.brandObj.previewUrl1,
            banner3: this.brandObj.previewUrl2
          },
          module: 'brand',
          zone: this.brandObj.zone,
          year: 2021
        };
        if (this.brandObj.id) {
          this.$api.putDesign_work({
            id: this.brandObj.id,
            ...temp
          }).then(() => {
            this.$message.success(this.$t('P15'));
          })
        } else {
          this.$api.postDesign_work(temp).then(res => {
            this.brandObj = res.detail;
            this.brandObj.id = res.id;
            this.brandObj['draft'] = true;
            this.qiyeleixing = JSON.parse(res.company_type);
            this.pingpaichanping = [res.product_type];
            this.pingpaixuqiu = res.product_need;
            this.haiwai = res.detail.haiwai;
            this.xianxia = res.detail.xianxia;
            this.$message.success(this.$t('Q37'));
          })
        }
      }
    },
    preViewInfo () {
      if (!this.brandObj.brandName || !this.brandObj.introduce || !this.brandObj.explain || !this.brandObj.adv || !this.brandObj.logoImg) {
        this.$message.warning('请先完善品牌主页信息');
        return;
      }
      this.$router.push({
        name: 'companyPreview',
        params: {
          from: 'jiangnan',
          brandObj: this.brandObj
        }
      })
    },
    update () {
      if (this.shifou[0] == 1) {
        let temp = {
          company_type: JSON.stringify(this.qiyeleixing),
          name: this.brandObj.name_zh,
          company_city: this.$Cookies.get('cecc-lang') !== 'en' ? this.brandObj.city[1] : this.brandObj.city,
          company_name: this.brandObj.ownerName,
          product_type: this.pingpaichanping[0],
          product_need: this.pingpaixuqiu,
          detail: {
            ...this.brandObj,
            shifou1: this.shifou1[0],
            haiwai: this.haiwai,
            xianxia: this.xianxia
          },
          images: {
            logo: this.brandObj.logoImg,
            banner1: this.brandObj.previewUrl,
            banner2: this.brandObj.previewUrl1,
            banner3: this.brandObj.previewUrl2
          },
          module: 'brand',
          zone: this.brandObj.zone,
          year: 2021
        };
        this.$api.postDesign_work(temp).then(() => {
          this.$message.success(this.$t('Q51'));
        })
      }
    },
    handleChange (value) {
      console.log(value)
    },
    backList () {
      this.$emit('backList');
    },
    checkIsJSON (str) {
      if (typeof str == 'string') {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == 'object' && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      }
      return false;
    }
  },
  components: {
  }
}
</script>

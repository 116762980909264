<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      display: flex;
      justify-content: flex-start;
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      height: 131px;
      margin-top: 10px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      &-item-flex {
        display: flex;
        line-height: 30px;
        margin-top: 20px;
        p {
          margin: 0;
        }
        &-left {
          width: 20.6%;
          p.person-center-wrapper-selfInfo-item-flex-left-main::before {
            content: " · ";
          }
        }
        &-right {
          width: 79%;
          text-align: left;
          margin-left: 1px;
          display: flex;
          .person-center-wrapper-selfInfo-item-flex-right-item {
            width: 100px;
            height: 100px;
            background: rgba(15, 17, 24, 1);
            border: 1px solid rgba(222, 230, 255, 0.3);
            text-align: center;
            line-height: 100px;
            margin-left: 10px;
          }
        }
      }
      &-container {
        margin-top: 20px;
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
  }
  /deep/.el-textarea__inner {
    border: 1px solid #999;
    border-radius: 0px;
    background-color: #0f1118;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .avatar-uploader {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .button {
    width: 140px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    margin: 30px auto 0;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 18px;
    cursor: pointer;
  }
}
.missing-information {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(7, 9, 17, 0.8);
  z-index: 12;
  &-container {
    width: 460px;
    height: 240px;
    background: rgba(23, 25, 32, 1);
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &-close {
      width: 18px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: rgba(222, 230, 255, 1);
      margin: 0;
      text-align: center;
      &:nth-child(2) {
        margin: 30px 0;
      }
    }
  }
  .sure-button {
    width: 140px;
    height: 40px;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
}
.button-container {
  width: 50%;
  margin: 0 auto;
  display: flex;
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <!-- <div class="person-center-wrapper-selfInfo"
           v-if="$Cookies.get('cecc-lang') !== 'en'">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            企业命题赛道
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-select v-model="type1Obj.demand_id"
                     clearable
                     placeholder="请选择企业命题赛道"
                     :style="{width: '255px'}">
            <el-option v-for="item in demandList"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div> -->

      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            <!-- {{ $t('A1') }} -->
            请选择参赛赛区
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-select v-model="type1Obj.zone"
                     clearable
                     placeholder="请选择参赛赛区"
                     :style="{width: '255px'}">
            <el-option v-for="item in zoneList"
                       :key="item.name"
                       :label="item.name"
                       :value="item.name">
            </el-option>
          </el-select>
          <el-input v-model="tempZone"
                    @click="changeZone"
                    v-if="type1Obj.zone === '其他'"
                    :style="{width: '120px', marginLeft: '12px'}"></el-input>
        </div>
      </div>

      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{ $t('B1') }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item"
             :style="{display: 'flex'}">
          <el-checkbox-group v-model="shejichuangye">
            <el-checkbox label="文化创意类">{{ $t('B2') }}</el-checkbox>
            <el-checkbox label="智能制造类">{{ $t('B3') }}</el-checkbox>
            <el-checkbox label="医疗健康类">{{ $t('B4') }}</el-checkbox>
            <el-checkbox label="其他">{{ $t('B5') }}</el-checkbox>
          </el-checkbox-group>
          <el-select v-model="type1Obj.moreType"
                     filterable
                     :placeholder="$t('H72')"
                     :style="{width: '255px',marginLeft: '40px'}">
            <el-option v-for="item in moreTypelist"
                       :key="item.value"
                       :label="$t(item.label)"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('B6')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="xiangmuzhuangtai">
            <el-checkbox label="仅概念">{{$t('B7')}}</el-checkbox>
            <el-checkbox label="DEMO/模型">{{$t('B8')}}</el-checkbox>
            <el-checkbox label="已上市">{{$t('B9')}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('B10')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="chuangyexuqiu">
            <el-checkbox label="产业转化">{{$t('B11')}}</el-checkbox>
            <el-checkbox label="线上推广">{{$t('B12')}}</el-checkbox>
            <el-checkbox label="线下渠道">{{$t('B13')}}</el-checkbox>
            <el-checkbox label="品牌与设计赋能">{{$t('B14')}}</el-checkbox>
            <el-checkbox label="技术与供应链渠道">{{$t('B15')}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo"
           :style="{height: '784px'}">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{$t('B16')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B17')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="type1Obj.name"
                      :placeholder="$t('H68')"
                      :style="{width: '255px'}"></el-input>
            <el-input v-model="type1Obj.name1"
                      :placeholder="$t('H69')"
                      :style="{width: '255px', marginLeft: '20px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B18')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input type="textarea"
                      :rows="6"
                      :placeholder="$t('H70')"
                      v-model="type1Obj.explain">
            </el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B19')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input type="textarea"
                      :rows="6"
                      :placeholder="$t('H71')"
                      v-model="type1Obj.yinli">
            </el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{$t('B20')}}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            {{$t('B21')}}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-upload class="avatar-uploader"
                       :action="imgUrl"
                       :show-file-list="false"
                       :on-success="onSuccess">
              <img v-if="type1Obj.pdfUrl"
                   class="avatar-uploader"
                   :src="'/api/static/'+type1Obj.thumbnail">
              <i v-else
                 class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
        <div class="button-container"
             v-if="type1Obj.draft">
          <div class="button"
               @click="saveInfoI">
            {{ $t('A21') }}
          </div>
          <div class="button"
               @click="confirmInfo">
            {{ $t('Q38') }}
          </div>
        </div>
      </div>
      <!-- <div class="person-center-wrapper-selfInfo"
           :style="{height: '534px'}">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            {{ $t('A31') }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
               {{ $t('A32') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-select v-model="type1Obj.owner"
                       filterable
                       :placeholder="$t('H65')"
                       :style="{width: '255px'}">
              <el-option v-for="item in ownerType"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="type1Obj.ownerCountry"
                       filterable
                      :placeholder="$t('H64')"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in citys"
                         :key="item.value"
                         :label="$Cookies.get('cecc-lang') === 'en' ? item.shortName : item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{ $t('A33') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-select v-model="type1Obj.designer"
                       filterable
                       :placeholder="$t('H65')"
                       :style="{width: '255px'}">
              <el-option v-for="item in ownerType"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="type1Obj.designerCountry"
                       filterable
                       :placeholder="$t('H64')"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in citys"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{ $t('A34') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="type1Obj.n1"
                      :placeholder="$t('H62')"
                      :style="{width: '255px'}"></el-input>
            <el-input v-model="type1Obj.j1"
                      :placeholder="$t('H63')"
                      :style="{width: '255px', marginLeft: '20px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p>
               {{ $t('A35') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="type1Obj.n2"
                     :placeholder="$t('H62')"
                      :style="{width: '255px'}"></el-input>
            <el-input v-model="type1Obj.j2"
                      :placeholder="$t('H63')"
                      :style="{width: '255px', marginLeft: '20px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p>
               {{ $t('A36') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="type1Obj.n3"
                      :placeholder="$t('H62')"
                      :style="{width: '255px'}"></el-input>
            <el-input v-model="type1Obj.j3"
                      :placeholder="$t('H63')"
                      :style="{width: '255px', marginLeft: '20px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p>
               {{ $t('A37') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="type1Obj.n4"
                      :placeholder="$t('H62')"
                      :style="{width: '255px'}"></el-input>
            <el-input v-model="type1Obj.j4"
                      :placeholder="$t('H63')"
                      :style="{width: '255px', marginLeft: '20px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p>
               {{ $t('A38') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="type1Obj.n5"
                      :placeholder="$t('H62')"
                      :style="{width: '255px'}"></el-input>
            <el-input v-model="type1Obj.j5"
                     :placeholder="$t('H63')"
                      :style="{width: '255px', marginLeft: '20px'}"></el-input>
          </div>
        </div>
        <div class="button"
             @click="saveInfo">
          {{ $t('A21') }}
        </div>
      </div> -->
    </div>
    <div class="missing-information"
         v-if="submit">
      <div class="missing-information-container">
        <img src="../assets/close.png"
             alt=""
             class="missing-information-container-close"
             @click="submit = false">
        <p>
          {{ $t('tips') }}
        </p>
        <p>
          {{ $t('Q39') }}
        </p>
        <div class="sure-button"
             @click="saveInfo"
             :style="{marginTop: '40px'}">
          {{$t('confirm')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityJSON from './city.json'

export default {
  name: 'design_start',
  props: {
    productionIDCI: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      submit: false,
      demandList: [],
      shejichuangye: [],
      xiangmuzhuangtai: [],
      chuangyexuqiu: [],
      type1Obj: {
        zone: '',
        demand_id: null,
        draft: true,
        moreType: '',
        name: '',
        name1: '',
        explain: '',
        yinli: '',
        owner: '',
        ownerCountry: '',
        designer: '',
        designerCountry: '',
        n1: '',
        j1: '',
        n2: '',
        j2: '',
        n3: '',
        j3: '',
        n4: '',
        j4: '',
        n5: '',
        j5: '',
        pdfUrl: '',
        thumbnail: ''
      },
      ownerType: [{
        value: '个人',
        label: 'H66'
      }, {
        value: '单位',
        label: 'H67'
      }],
      moreTypelist: [],

      citys: cityJSON.citys,
      imgUrl: '/api/upload_cert?category=whitepaper',
      zoneList: [],
      tempZone: ''
    }
  },
  created () {
    if (this.productionIDCI) {
      if (this.productionIDCI.module !== "product") {
        this.$message.error(this.$t('P16'));
      }
      this.type1Obj = this.productionIDCI.detail;
      this.type1Obj['draft'] = this.productionIDCI.draft;
      this.shejichuangye = [this.productionIDCI.product_type];
      this.xiangmuzhuangtai = [this.productionIDCI.product_status];
      this.chuangyexuqiu = this.productionIDCI.product_need;
    }
    this.$api.getDesign_demand_list_simple().then(res => {
      this.demandList = res.demands
    })
    this.$api.getZones().then(res => {
      this.zoneList = res.zones
    })
  },
  mounted () {
  },
  computed: {
  },
  watch: {
    shejichuangye () {
      let wen = [{
        label: 'L1',
        value: '非遗文化'
      }, {
        label: 'L2',
        value: '艺术展览'
      }, {
        label: 'L3',
        value: '电子竞技'
      }, {
        label: 'L4',
        value: '创意设计'
      }, {
        label: 'L5',
        value: '其他'
      }];
      let zhi = [{
        label: 'L6',
        value: '电子科技'
      }, {
        label: 'L7',
        value: '智慧产品开发及应用'
      }, {
        label: 'L8',
        value: '人工智能'
      }, {
        label: 'L9',
        value: '智能材料'
      }, {
        label: 'L10',
        value: '智慧汽车'
      }, {
        label: 'L11',
        value: '无人机运用'
      }, {
        label: 'L12',
        value: '环保节能产业'
      }, {
        label: 'L13',
        value: '其他'
      }]
      let jin = [{
        label: 'L14',
        value: '生物科技'
      }, {
        label: 'L15',
        value: '医疗器械'
      }, {
        label: 'L16',
        value: '基因测序'
      }, {
        label: 'L17',
        value: '实验平台'
      }, {
        label: 'L18',
        value: '靶向治疗'
      }]
      let other = [{
        label: 'L19',
        value: '其他'
      }]
      if (this.shejichuangye.length > 1) {
        this.shejichuangye.shift();
      }
      this.moreTypelist = this.shejichuangye[0] === '文化创意类' ? wen : this.shejichuangye[0] === '智能制造类' ? zhi : this.shejichuangye[0] === '其他' ? other : jin;
    },
    xiangmuzhuangtai () {
      if (this.xiangmuzhuangtai.length > 1) {
        this.xiangmuzhuangtai.shift();
      }
    }
  },
  methods: {
    onSuccess (file) {
      this.type1Obj.pdfUrl = file.filename;
      this.type1Obj.thumbnail = file.thumbnail;
    },
    confirmInfo () {
      if (!this.type1Obj.name || !this.shejichuangye[0] || !this.type1Obj.moreType || !this.xiangmuzhuangtai[0] ||
        !this.type1Obj.explain || !this.type1Obj.yinli) {
        this.$message.warning(this.$t('Q30'));
        return;
      }
      this.submit = true;
    },
    saveInfoI () {
      let temp = {
        module: 'product',
        draft: true,
        name: this.type1Obj.name,
        demand_id: this.type1Obj.demand_id || null,
        product_type: this.shejichuangye[0],
        product_type2: this.type1Obj.moreType,
        product_status: this.xiangmuzhuangtai[0],
        product_need: this.chuangyexuqiu,
        owner_type: this.type1Obj.owner,
        owner_country: this.type1Obj.ownerCountry,
        author_type: this.type1Obj.designer,
        author_country: this.type1Obj.designerCountry,
        main_author: this.type1Obj.n1,
        detail: this.type1Obj,
        certificates: {
          plan: this.type1Obj.pdfUrl
        },
        zone: this.type1Obj.zone,
        year: 2021
      };
      if (this.productionIDCI) {
        this.$api.putDesign_work(
          {
            id: this.productionIDCI.id,
            ...temp
          }).then(res => {
            this.type1Obj = res.detail;
            this.shejichuangye = [res.product_type];
            this.xiangmuzhuangtai = [res.product_status];
            this.chuangyexuqiu = res.product_need;
            this.submit = false;
            this.$message.success(this.$t('P15'));
          })
      } else {
        this.$api.postDesign_work(temp).then(res => {
          this.type1Obj = res.detail;
          this.shejichuangye = [res.product_type];
          this.xiangmuzhuangtai = [res.product_status];
          this.chuangyexuqiu = res.product_need;
          this.submit = false;
          this.$message.success(this.$t('Q37'));
        })
      }
    },
    saveInfo () {
      let temp = {
        module: 'product',
        draft: false,
        name: this.type1Obj.name,
        demand_id: this.type1Obj.demand_id || null,
        product_type: this.shejichuangye[0],
        product_type2: this.type1Obj.moreType,
        product_status: this.xiangmuzhuangtai[0],
        product_need: this.chuangyexuqiu,
        owner_type: this.type1Obj.owner,
        owner_country: this.type1Obj.ownerCountry,
        author_type: this.type1Obj.designer,
        author_country: this.type1Obj.designerCountry,
        main_author: this.type1Obj.n1,
        detail: this.type1Obj,
        certificates: {
          plan: this.type1Obj.pdfUrl
        },
        zone: this.type1Obj.zone,
        year: 2021
      };
      if (this.productionIDCI) {
        this.$api.putDesign_work(
          {
            id: this.productionIDCI.id,
            ...temp
          }).then(res => {
            this.type1Obj = res.detail;
            this.shejichuangye = [res.product_type];
            this.xiangmuzhuangtai = [res.product_status];
            this.chuangyexuqiu = res.product_need;
            this.submit = false;
            this.$message.success(this.$t('P15'));
          })
      } else {
        this.$api.postDesign_work(temp).then(res => {
          this.type1Obj = res.detail;
          this.shejichuangye = [res.product_type];
          this.xiangmuzhuangtai = [res.product_status];
          this.chuangyexuqiu = res.product_need;
          this.submit = false;
          this.$message.success(this.$t('Q37'));
        })
      }
    },
    changeZone () {
      this.type1Obj.zone = this.tempZone;
    }
  },
  components: {
  }
}
</script>

<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      display: flex;
      justify-content: flex-start;
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      padding: 20px 0;
      margin-top: 10px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      .person-center-wrapper-selfInfo-item-dasai {
        width: 85%;
        background: rgba(23, 25, 32, 1);
        padding: 20px 24px;
        p {
          margin: 0;
          font-family: "PingFangSC-Regular", "PingFang SC";
          color: rgba(222, 230, 255, 1);
          font-weight: 400;
          &:nth-child(1) {
            height: 20px;
            font-size: 14px;
            line-height: 20px;
          }
          &:nth-child(2) {
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
      .person-center-wrapper-selfInfo-item-xize {
        width: 89%;
        display: flex;
        justify-content: space-between;
        &-item {
          width: 30%;
          height: 230px;
          background: rgba(23, 25, 32, 1);
          padding-top: 20px;
          padding-left: 24px;
          p {
            margin: 0;
            font-family: "PingFangSC-Regular", "PingFang SC";
            font-weight: 400;
            color: rgba(222, 230, 255, 1);
            &:nth-child(1) {
              font-size: 14px;
              line-height: 20px;
            }
            &:nth-child(2) {
              margin-top: 20px;
              font-size: 12px;
              line-height: 24px;
            }
          }
        }
      }
      .person-center-wrapper-selfInfo-item-richeng {
        width: 89%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &-item {
          width: 30.3%;
          height: 84px;
          background: rgba(23, 25, 32, 1);
          padding-left: 20px;
          margin-top: 15px;
          font-family: "PingFangSC-Regular", "PingFang SC";
          font-weight: 400;
          color: rgba(222, 230, 255, 1);
          &-top {
            display: flex;
            padding-top: 15px;
            &-left {
              width: 20px;
              height: 20px;
              background: center / contain no-repeat url("../assets/9.png");
              text-align: center;
              line-height: 20px;
            }
            &-right {
              margin-left: 13px;
            }
          }
          &-main {
            font-size: 12px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }
      .person-center-wrapper-selfInfo-jiang {
        width: 85%;
        background: rgba(23, 25, 32, 1);
        text-align: center;
        padding: 10px;
        font-size: 12px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 17px;
        margin-top: 1px;
      }
      .person-center-wrapper-selfInfo-flex {
        width: 85%;
        background: rgba(23, 25, 32, 1);
        text-align: center;
        padding: 10px;
        font-size: 12px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        &-left {
          width: 26.6%;
        }
        &-right {
          width: 74%;
          text-align: left;
          margin-left: 1px;
        }
      }
      .person-center-wrapper-selfInfo-download {
        display: flex;
        justify-content: space-between;
        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  .actived {
    font-size: 14px;
    font-weight: 600;
    color: rgba(85, 119, 224, 1);
    background: rgba(30, 33, 43, 1);
    border-bottom: 2px solid rgba(85, 119, 224, 1);
  }
  .r-button {
    width: 140px;
    height: 40px;
    margin: 28px auto;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <!-- <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
          </div> -->
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          1.设计作品类证书 <br />
          2.设计创业类证书 <br />
          3.原创名品类证书 <br />
          4.无畏人才类证书 <br />
          上述获奖作品的设计师，获奖创业项目团队与获奖原创名品单位，可获得长三角“无畏”全球创意人才联盟、长三角“无畏” 全球创意创业联盟、长三角“无畏”全球创意创新联盟相关证书。 中文证书盖章机构:长江三角洲城市经济协调会创意经济合作专业委员会(以下简称:长三角创意经济专委会，即 CECC); 上海无畏创意经济区域合作促进中心
        </div>
      </div>
    </div>
    <Common-footer :style="{background: '#0F1118'}"></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';

export default {
  name: 'IDC-award',
  data () {
    return {
    }
  },
  created () {
    if (this.$Cookies.get('cecc-lang') === 'en') {
      this.$router.push({
        name: 'building'
      })
    }
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    download (url) {
      if (url) {
        window.open(window.location.origin + url);
      }
    }
  },
  components: {
    CommonFooter
  }
}
</script>

<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  min-height: 800px;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-selfInfo {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      padding-bottom: 28px;
      background: #0f1118;
      &-header {
        width: 80%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        justify-content: space-between;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          display: flex;
          &-left {
            width: 4px;
            height: 20px;
            background: rgba(85, 119, 224, 1);
            border-radius: 5px;
          }
          &-right {
            font-size: 18px;
            font-weight: 400;
            color: rgba(85, 119, 224, 1);
            margin-left: 10px;
          }
        }
        &-right {
          display: flex;
          &-left {
            width: 100px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            border: 1px solid #fff;
            border-radius: 4px;
            cursor: pointer;
          }
          &-right {
            width: 100px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            border: 1px solid #fff;
            border-radius: 4px;
            cursor: pointer;
            margin-left: 18px;
          }
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      &-item-flex {
        display: flex;
        line-height: 30px;
        margin-top: 20px;
        p {
          margin: 0;
        }
        &-left {
          width: 20.6%;
          p.person-center-wrapper-selfInfo-item-flex-left-main::before {
            content: " · ";
          }
        }
        &-right {
          width: 79%;
          text-align: left;
          margin-left: 1px;
          display: flex;
          .person-center-wrapper-selfInfo-item-flex-right-item {
            width: 100px;
            height: 100px;
            background: rgba(15, 17, 24, 1);
            border: 1px solid rgba(222, 230, 255, 0.3);
            text-align: center;
            line-height: 100px;
            margin-left: 10px;
          }
        }
      }
      &-item-fl {
        width: 90%;
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        &-right {
          display: flex;
          &-button {
            width: 90px;
            text-align: center;
            border: 1px solid rgb(222, 230, 255);
            border-radius: 4px;
            margin-left: 18px;
            cursor: pointer;
          }
        }
      }
      &-container {
        margin-top: 20px;
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  .missing-information {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(7, 9, 17, 0.8);
    z-index: 12;
    &-container {
      width: 460px;
      height: 240px;
      background: rgba(23, 25, 32, 1);
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-close {
        width: 18px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        margin: 0;
        text-align: center;
        &:nth-child(2) {
          margin: 30px 0;
        }
      }
    }
    .sure-button {
      width: 140px;
      height: 40px;
      margin: 0 auto;
      text-align: center;
      line-height: 40px;
      letter-spacing: 0;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 29px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-header">
          <div class="person-center-wrapper-selfInfo-header-left">
            <div class="person-center-wrapper-selfInfo-header-left-left">
            </div>
            <div class="person-center-wrapper-selfInfo-header-left-right">
              {{ $t('moreM') }}
            </div>
          </div>
          <div class="person-center-wrapper-selfInfo-header-right">
            <!-- <div class="person-center-wrapper-selfInfo-header-right-left"
                 @click="modifyProduction(null)">
              {{ $t('Q40') }}
            </div> -->
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo"
           v-for="(item, index) in designWorkList"
           :key="index">
        <div class="person-center-wrapper-selfInfo-header">
          <div class="person-center-wrapper-selfInfo-header-left">
            <div class="person-center-wrapper-selfInfo-header-left-left">
            </div>
            <div class="person-center-wrapper-selfInfo-header-left-right">
              {{ $t('Q42') }} : {{ item.name }}
            </div>
          </div>
          <!-- <div class="person-center-wrapper-selfInfo-header-right">
            <div class="person-center-wrapper-selfInfo-header-right-left"
                 @click="modifyProduction(item)">
              {{ $t('Q49') }}
            </div>
            <div class="person-center-wrapper-selfInfo-header-right-right"
                 @click="detele(item)">
              {{ $t('Q41') }}
            </div>
          </div> -->
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{ $t('Q43') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            {{ item.created.split(' ')[0].replace(/-/g, '') + item.user_id + item.id }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{ $t('Q44') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            {{ item.draft ? $t('Q47') : $t('Q46') }}
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              {{ $t('Q45') }}
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            {{ item.module === 'design' ? $t('IDC_forth1_1') : item.module === 'product' ? $t('IDC_forth1_2') : $t('IDC_forth1_3') }}
          </div>
        </div>
      </div>
    </div>
    <div class="missing-information"
         v-if="deleteModal">
      <div class="missing-information-container">
        <img src="../assets/close.png"
             alt=""
             class="missing-information-container-close"
             @click="deleteModal = false">
        <p>
          {{ $t('tips') }}
        </p>
        <p>
          {{ $t('Q50') }}
        </p>
        <div class="sure-button"
             @click="deleteProduction"
             :style="{marginTop: '40px'}">
          {{$t('confirm')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'enter',
  props: {
  },
  data () {
    return {
      designWorkList: [],
      deleteModal: false,
      deleteItem: null
    }
  },
  created () {
    // if (this.$Cookies.get('cecc-lang') === 'en') {
    //   this.$router.push({
    //     name: 'building'
    //   })
    //   return;
    // }
    this.$api.getDesign_work().then(res => {
      this.designWorkList = res.items;
    })
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    detele (production) {
      this.deleteItem = production;
      this.deleteModal = true;
    },
    deleteProduction () {
      let findIndex = this.designWorkList.findIndex(item => item.id === this.deleteItem.id);
      this.$api.deleteDesign_work({
        id: this.deleteItem.id
      }).then(() => {
        this.deleteModal = false;
        this.designWorkList.splice(findIndex, 1);
        this.$message.success('删除成功');
      })
    },
    modifyProduction (production) {
      this.$emit('toProduction', production);
    }
  },
  components: {
  }
}
</script>

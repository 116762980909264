<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      display: flex;
      justify-content: flex-start;
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      padding-bottom: 28px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      &-item-flex {
        display: flex;
        line-height: 30px;
        margin-top: 20px;
        p {
          margin: 0;
        }
        &-left {
          width: 20.6%;
          p.person-center-wrapper-selfInfo-item-flex-left-main::before {
            content: " · ";
          }
        }
        &-right {
          width: 79%;
          text-align: left;
          margin-left: 1px;
          display: flex;
          .person-center-wrapper-selfInfo-item-flex-right-item {
            width: 100px;
            height: 100px;
            background: rgba(15, 17, 24, 1);
            border: 1px solid rgba(222, 230, 255, 0.3);
            text-align: center;
            line-height: 100px;
            margin-left: 10px;
          }
        }
      }
      &-item-fl {
        width: 90%;
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        &-right {
          display: flex;
          &-button {
            width: 90px;
            text-align: center;
            border: 1px solid rgb(222, 230, 255);
            border-radius: 4px;
            margin-left: 18px;
            cursor: pointer;
          }
        }
      }
      &-container {
        margin-top: 20px;
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
  }
  /deep/.el-textarea__inner {
    border: 1px solid #999;
    border-radius: 0px;
    background-color: #0f1118;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .avatar-uploader {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #0f1118;
    border-radius: 0px;
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
  .button-wrapper {
    width: 50%;
    margin: 0 auto;
    display: flex;
  }
  .button {
    width: 140px;
    height: 36px;
    text-align: center;
    overflow: hidden;
    line-height: 36px;
    margin: 30px auto 0;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 18px;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            已发布需求
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-fl"
             v-for="(item, index) in demandList"
             :key="index">
          <template v-if="item.selected">
            <div class="person-center-wrapper-selfInfo-item-fl-left">
              【已发布】 {{item.design_type}} -- {{item.design_type2}}
            </div>
            <div class="person-center-wrapper-selfInfo-item-fl-right">
              {{item.title}}
              <div class="person-center-wrapper-selfInfo-item-fl-right-button"
                   @click="deleteDemand(item)">删除需求</div>
            </div>
          </template>
          <template v-else>
            <div class="person-center-wrapper-selfInfo-item-fl-left">
              【审核中】{{item.design_type}} -- {{item.design_type2}}
            </div>
            <div class="person-center-wrapper-selfInfo-item-fl-right">
              {{item.title}}
              <div class="person-center-wrapper-selfInfo-item-fl-right-button"
                   @click="deleteDemand(item)">删除需求</div>
            </div>
          </template>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            发布新需求
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            企业信息
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              企业名称
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input placeholder="请输入您的企业名称，10字内"
                      v-model="demandObj.company_name">
            </el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              企业简介
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input type="textarea"
                      :rows="3"
                      placeholder="简介，300字内"
                      v-model="demandObj.company_description">
            </el-input>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            赛事信息
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              赛题名称
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input placeholder="请输入赛事标题，10字内"
                      v-model="demandObj.title">
            </el-input>
          </div>
        </div>
      </div>

      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            设计需求
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item"
             :style="{display: 'flex'}">
          <el-checkbox-group v-model="designType">
            <el-checkbox label="文创板块">文创板块</el-checkbox>
            <el-checkbox label="时尚板块">时尚板块</el-checkbox>
            <el-checkbox label="工业设计板块">工业设计板块</el-checkbox>
            <el-checkbox label="创新设计板块">创新设计板块</el-checkbox>
          </el-checkbox-group>
          <el-select v-model="demandObj.design_type2"
                     filterable
                     placeholder="请选择细分类"
                     :style="{width: '255px', marginLeft: '20px'}">
            <el-option v-for="item in designTypeList"
                       :key="item.value"
                       :label="$t(item.label)"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            入驻企业/项目需求
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item"
             :style="{display: 'flex'}">
          <el-checkbox-group v-model="projectType">
            <el-checkbox label="文化创意类">文化创意类</el-checkbox>
            <el-checkbox label="智能制造类">智能制造类</el-checkbox>
            <el-checkbox label="医疗健康类">医疗健康类</el-checkbox>
            <el-checkbox label="其他">其他</el-checkbox>
          </el-checkbox-group>
          <template v-if="projectType[0] !== '其他'">
            <el-select v-model="demandObj.project_type2"
                       filterable
                       placeholder="请选择细分类"
                       :style="{width: '255px', marginLeft: '20px'}">
              <el-option v-for="item in projectTypeList"
                         :key="item.value"
                         :label="$t(item.label)"
                         :value="item.value">
              </el-option>
            </el-select>
          </template>
          <template v-else>
            <el-input v-model="demandObj.project_type2"
                      placeholder="请输入入驻企业/项目需求 "
                      :style="{width: '255px', marginLeft: '20px'}"></el-input>
          </template>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            奖项设置
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="mo">
            <el-checkbox label="是">
              孵化基金
              <el-input v-model="demandObj.mon1"
                        placeholder=""
                        :style="{width: '120px', margin: '0 6px'}"></el-input>
              元/项目,总计拟
              <el-input v-model="demandObj.mon2"
                        placeholder=""
                        :style="{width: '120px', margin: '0 6px'}"></el-input>元

            </el-checkbox>
            <el-checkbox label="否"
                         :style="{display: 'block', marginTop: '16px'}">
              成果采纳
              <el-input v-model="demandObj.mon3"
                        placeholder=""
                        :style="{width: '120px', margin: '0 6px'}"></el-input>
              元/项目
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            作品形式
          </div>
        </div>
        <p style="width: 90%; marginLeft: 5%">原则：要求原创、无抄袭、版权说明。</p>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="xingshi">
            <el-checkbox label="平面图版">平面图版</el-checkbox>
            <el-checkbox label="3D建模文件">3D建模文件</el-checkbox>
            <el-checkbox label="作品说明">作品说明</el-checkbox>
            <el-checkbox label="视频文件">视频文件</el-checkbox>
            <el-checkbox label="策划方案">策划方案</el-checkbox>
            <el-checkbox label="项目计划书">项目计划书</el-checkbox>
            <el-checkbox label="其他">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            是否要求具备知识产权
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="shifou">
            <el-checkbox label="是">是</el-checkbox>
            <el-checkbox label="否">否</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            企业提供材料
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item">
          <el-checkbox-group v-model="cailiao">
            <el-checkbox label="企业需求介绍（命题单位列出具体参赛作品的要求）"> 企业需求介绍（命题单位列出具体参赛作品的要求）</el-checkbox>
            <el-checkbox label="产品原始资料"> 产品原始资料</el-checkbox>
            <el-checkbox label="园区扶持政策"> 园区扶持政策</el-checkbox>
            <el-checkbox label="产品图纸"> 产品图纸</el-checkbox>
            <el-checkbox label="其他相关资料"> 其他相关资料</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              企业材料上传
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-upload class="avatar-uploader"
                       :action="imgUrl"
                       :show-file-list="false"
                       :on-success="onSuccess"
                       :on-error="onError">
              <img v-if="demandObj.thumbnail"
                   class="avatar-uploader"
                   :src="'/api/static/'+demandObj.thumbnail">
              <i v-else
                 class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            联系信息
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              联系人
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="demandObj.contact_name"
                      placeholder="请输入联系人姓名"
                      :style="{width: '780px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              联系电话
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="demandObj.contact_phone"
                      placeholder="请输入联系电话"
                      :style="{width: '780px'}"></el-input>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              地址
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <el-input v-model="demandObj.contact_address"
                      placeholder="请输入联系地址"
                      :style="{width: '780px'}"></el-input>
          </div>
        </div>
        <div class="button-wrapper">
          <div class="button"
               @click="saveInfo">
            {{ $t('A21') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'enter',
  props: {
  },
  data () {
    return {
      designType: [],
      designTypeList: [],
      projectType: [],
      shifou: [],
      mo: [],
      xingshi: [],
      cailiao: [],
      projectTypeList: [],
      qiyeleixing: [],
      demandList: [],
      demandObj: {
        company_name: '',
        company_description: '',
        title: '',
        contact_name: '',
        contact_phone: '',
        contact_address: '',
        dataUrl: '',
        thumbnail: '',
        design_type2: '',
        project_type2: '',
      },
      imgUrl: '/api/upload_material?category=demand'
    }
  },
  created () {
    if (this.$Cookies.get('cecc-lang') === 'en') {
      this.$router.push({
        name: 'building'
      })
      return;
    }
    if (JSON.parse(this.$Cookies.get('cecc-user')).org_type !== 1) {
      this.$message.warning('该功能仅对企业用户开放，请使用企业账号登录或注册');
      return;
    }
    this.$api.getDesign_demand().then(resp => {
      this.demandList = resp.demands;
      // let res = resp.demands[0];
      // this.demandObj = res;
      // this.designType = [res.design_type];
      // this.projectType = [res.project_type];
      // this.shifou = [res.detail.shifou];
      // this.demandObj.mon1 = res.detail.mon1;
      // this.demandObj.mon2 = res.detail.mon2;
      // this.demandObj.mon3 = res.detail.mon3;
      // this.cailiao = [res.detail.cailiao];
      // this.xingshi = [res.detail.xingshi];
      // this.demandObj.thumbnail = res.detail.thumbnail;
    })
  },
  mounted () {
  },
  computed: {
  },
  watch: {
    designType () {
      if (this.designType.length > 1) {
        this.designType.shift();
      }
      let designTypeList1 = [
        {
          label: 'D1',
          value: '城市文化创意产品设计'
        },
        {
          label: 'D2',
          value: '旅游纪念品设计'
        },
        {
          label: 'D3',
          value: '城市特色产品设计（含食品、农产品等）'
        },
        {
          label: 'D4',
          value: '区域品牌设计'
        },
        {
          label: 'D5',
          value: '城市形象相关设计'
        },
        {
          label: 'D6',
          value: '其他'
        }
      ]
      let designTypeList2 = [
        {
          label: 'D7',
          value: '流行服饰'
        },
        {
          label: 'D8',
          value: '母婴用品'
        },
        {
          label: 'D9',
          value: '时尚穿戴产品设计'
        },
        {
          label: 'D10',
          value: '时尚产品创新设计'
        },
        {
          label: 'D11',
          value: '时尚周边产品设计'
        },
        {
          label: 'D12',
          value: '其他'
        }
      ]
      let designTypeList3 = [
        {
          label: 'D13',
          value: '电子数码产品'
        },
        {
          label: 'D14',
          value: '厨房卫浴家电'
        },
        {
          label: 'D15',
          value: '设备设施器具'
        },
        {
          label: 'D16',
          value: '化妆品品牌设计'
        },
        {
          label: 'D17',
          value: '特色产业产品包装设计'
        },
        {
          label: 'D18',
          value: '生活杂项用品'
        },
        {
          label: 'D19',
          value: '其他'
        }
      ]
      let designTypeList4 = [
        {
          label: 'D20',
          value: '城市特色主题数字创意设计'
        },
        {
          label: 'D21',
          value: '特色产业的新方向设计'
        },
        {
          label: 'D22',
          value: '新材料应用设计'
        },
        {
          label: 'D23',
          value: '技术创新应用'
        },
        {
          label: 'D24',
          value: '模式创新应用'
        },
        {
          label: 'D25',
          value: '其他'
        }
      ]
      this.designTypeList = this.designType[0] === '文创板块' ? designTypeList1 :
        this.designType[0] === '时尚板块' ? designTypeList2 :
          this.designType[0] === '工业设计板块' ? designTypeList3 :
            this.designType[0] === '创新设计板块' ? designTypeList4 : [];
    },
    cailiao () {
      if (this.cailiao.length > 1) {
        this.cailiao.shift();
      }
    },
    shifou () {
      if (this.shifou.length > 1) {
        this.shifou.shift();
      }
    },
    projectType () {
      if (this.projectType.length > 1) {
        this.projectType.shift();
      }
      let projectTypeList1 = [
        {
          label: 'D26',
          value: '非遗文化'
        },
        {
          label: 'D27',
          value: '艺术展览'
        },
        {
          label: 'D28',
          value: '电子竞技'
        },
        {
          label: 'D29',
          value: '创意设计'
        },
        {
          label: 'D30',
          value: '其他'
        }
      ]
      let projectTypeList2 = [
        {
          label: 'D31',
          value: '电子科技'
        },
        {
          label: 'D32',
          value: '智慧产品开发及应用'
        },
        {
          label: 'D33',
          value: '人工智能'
        },
        {
          label: 'D34',
          value: '智能材料'
        },
        {
          label: 'D35',
          value: '智慧汽车'
        },
        {
          label: 'D36',
          value: '无人机运用'
        },
        {
          label: 'D37',
          value: '环保节能产业'
        },
        {
          label: 'D38',
          value: '其他'
        }
      ]
      let projectTypeList3 = [
        {
          label: 'D39',
          value: '生物科技'
        },
        {
          label: 'D40',
          value: '医疗器械'
        },
        {
          label: 'D41',
          value: '基因测序'
        },
        {
          label: 'D42',
          value: '实验平台'
        },
        {
          label: 'D43',
          value: '靶向治疗'
        }
      ]
      this.projectTypeList = this.projectType[0] === '文化创意类' ? projectTypeList1 :
        this.projectType[0] === '智能制造类' ? projectTypeList2 :
          this.projectType[0] === '医疗健康类' ? projectTypeList3 : [];
    }
  },
  methods: {
    onSuccess (file) {
      this.demandObj.dataUrl = file.filename;
      this.demandObj.thumbnail = file.thumbnail;
      this.$message.success('上传成功');
    },
    onError () {
      this.$message.error('上传失败');
    },
    saveInfo () {
      // if (JSON.parse(this.$Cookies.get('cecc-user')).org_type !== 1) {
      //   this.$message.warning('该功能仅对企业用户开放，请使用企业账号登录或注册');
      //   return;
      // }
      if (!this.demandObj.thumbnail) {
        this.$message.warning('上传资料不能为空');
        return;
      }
      if (!this.demandObj.title || !this.demandObj.company_name || !this.demandObj.company_description ||
        !this.designType[0] || !this.demandObj.design_type2 || !this.projectType[0]
        || !this.demandObj.contact_name || !this.demandObj.contact_phone || !this.demandObj.contact_address) {
        this.$message.warning('请先完善信息');
        return;
      }
      let temp = {
        title: this.demandObj.title,
        company_name: this.demandObj.company_name,
        company_description: this.demandObj.company_description,
        design_type: this.designType[0],
        design_type2: this.demandObj.design_type2,
        project_type: this.projectType[0],
        project_type2: this.demandObj.project_type2,
        contact_name: this.demandObj.contact_name,
        contact_phone: this.demandObj.contact_phone,
        contact_address: this.demandObj.contact_address,
        detail: {
          ...this.demandObj,
          shifou: this.shifou[0],
          cailiao: this.cailiao[0],
          xingshi: this.xingshi,
          mo: this.mo[0]
        },
        certificates: {
          dataUrl: this.demandObj.dataUrl
        },
      };
      this.$api.postDesign_demand(temp).then(() => {
        this.$message.success('保存成功');
      })
    },
    deleteDemand (demand) {
      let findIndex = this.demandList.findIndex(item => item.id === demand.id);
      this.$api.deleteDesign_demand({
        id: demand.id
      }).then(() => {
        this.demandList.splice(findIndex, 1);
        this.$message.success('删除成功');
      })
    }
  },
  components: {
  }
}
</script>
